import React from "react";
import Section from "../../Company/Pricing/Section";
import PricingPage from "../../Company/Pricing/PricingPage";

const Pricing = () => {
  document.title = "Test Technique | RecruTech";
  return (
    <React.Fragment>
      <Section />
      <PricingPage />
    </React.Fragment>
  );
};

export default Pricing;
