import * as ProfileActionTypes from "../types";

const initialState = {
  domainTypes: [],
  contractTypes: [],
  levelTypes: [],
  languageTypes: [],
  allExperiences: [],
  allEducations: [],
  allSkills: [],
  allLanguages: [],
  loading: false,
  loadingFile: false,
  errorFile: undefined,
  error: undefined,
  educationLoading: false,
  educationError: undefined,
  skillLoading: false,
  skillError: undefined,
  languageLoading: false,
  languageError: undefined,
  selectedDetails: null,
  infoPersoDetails: null,
  infoPersoLoading: false,
  infoPersoError: undefined,
  infoSituationDetails: null,
  infoSituationLoading: false,
  infoSituationError: undefined,
  infoPreferenceDetails: null,
  infoPreferenceLoading: false,
  infoPreferenceError: undefined,
};

const profileReducer = (state = initialState, action) => {
  switch (action.type) {
    //language
    case ProfileActionTypes.FETCH_LANGUAGE_TYPES_REQUEST:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case ProfileActionTypes.FETCH_LANGUAGE_TYPES_SUCCESS:
      return {
        ...state,
        loading: false,
        languageTypes: action.payload.data,
        error: null,
      };
    case ProfileActionTypes.FETCH_LANGUAGE_TYPES_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    // level
    case ProfileActionTypes.FETCH_LEVEL_TYPES_REQUEST:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case ProfileActionTypes.FETCH_LEVEL_TYPES_SUCCESS:
      return {
        ...state,
        loading: false,
        levelTypes: action.payload.data,
        error: null,
      };
    case ProfileActionTypes.FETCH_LEVEL_TYPES_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    // domain
    case ProfileActionTypes.FETCH_DOMAIN_TYPES_REQUEST:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case ProfileActionTypes.FETCH_DOMAIN_TYPES_SUCCESS:
      // console.log("rrr", action.payload.data);
      return {
        ...state,
        loading: false,
        domainTypes: action.payload.data,
        error: null,
      };
    case ProfileActionTypes.FETCH_DOMAIN_TYPES_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    // contract
    case ProfileActionTypes.FETCH_CONTRACT_TYPES_REQUEST:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case ProfileActionTypes.FETCH_CONTRACT_TYPES_SUCCESS:
      return {
        ...state,
        loading: false,
        contractTypes: action.payload.data,
        error: null,
      };
    case ProfileActionTypes.FETCH_CONTRACT_TYPES_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    case ProfileActionTypes.FETCH_CANDIDATE_EXPERIENCE_REQUEST:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case ProfileActionTypes.FETCH_CANDIDATE_EXPERIENCE_SUCCESS:
      // console.log('rrr',action.payload.data);
      return {
        ...state,
        loading: false,
        allExperiences: action.payload.data,
        error: null,
      };

    case ProfileActionTypes.FETCH_CANDIDATE_EXPERIENCE_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    case ProfileActionTypes.ADD_CANDIDATE_EXPERIENCE_REQUEST:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case ProfileActionTypes.ADD_CANDIDATE_EXPERIENCE_SUCCESS:
      return {
        ...state,
        loading: false,
        error: null,
      };

    case ProfileActionTypes.ADD_CANDIDATE_EXPERIENCE_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    case ProfileActionTypes.UPDATE_CANDIDATE_EXPERIENCE_REQUEST:
      return {
        ...state,
        loading: false,
        error: null,
      };

    case ProfileActionTypes.UPDATE_CANDIDATE_EXPERIENCE_SUCCESS:
      return {
        ...state,
        loading: false,
        error: null,
      };

    case ProfileActionTypes.UPDATE_CANDIDATE_EXPERIENCE_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    case ProfileActionTypes.DELETE_CANDIDATE_EXPERIENCE_REQUEST:
      return {
        ...state,
        loading: false,
        error: null,
      };

    case ProfileActionTypes.DELETE_CANDIDATE_EXPERIENCE_SUCCESS:
      return {
        ...state,
        loading: false,
        error: null,
      };

    case ProfileActionTypes.DELETE_CANDIDATE_EXPERIENCE_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    case ProfileActionTypes.SELECT_PROFILE_REQUEST:
      return {
        ...state,
        loading: true,
        error: null,
        selectedDetails: undefined, // Reset selectedDetails on new request
      };
    case ProfileActionTypes.SELECT_PROFILE_SUCCESS:
      // console.log('rrr',action.payload);
      return {
        ...state,
        loading: false,
        selectedDetails: action.payload,
        error: null,
      };
    case ProfileActionTypes.SELECT_PROFILE_FAILURE:
      return {
        ...state,
        loading: false,
        educationError: action.payload,
      };

    case "RESET_SELECTED_DETAILS":
      return {
        ...state,
        selectedDetails: null,
        loading: false,
      };

    case ProfileActionTypes.FETCH_CANDIDATE_EDUCATION_REQUEST:
      return {
        ...state,
        educationLoading: true,
        educationError: null,
      };
    case ProfileActionTypes.FETCH_CANDIDATE_EDUCATION_SUCCESS:
      return {
        ...state,
        educationLoading: false,
        allEducations: action.payload.data,
        educationError: null,
      };
    case ProfileActionTypes.FETCH_CANDIDATE_EDUCATION_FAILURE:
      return {
        ...state,
        educationLoading: false,
        educationError: action.payload,
      };
    case ProfileActionTypes.ADD_CANDIDATE_EDUCATION_REQUEST:
      return {
        ...state,
        educationLoading: true,
        educationError: null,
      };
    case ProfileActionTypes.ADD_CANDIDATE_EDUCATION_SUCCESS:
      return {
        ...state,
        educationLoading: false,
        educationError: null,
      };
    case ProfileActionTypes.ADD_CANDIDATE_EDUCATION_FAILURE:
      return {
        ...state,
        educationLoading: false,
        educationError: action.payload,
      };
    case ProfileActionTypes.UPDATE_CANDIDATE_EDUCATION_REQUEST:
      return {
        ...state,
        educationLoading: false,
        educationError: null,
      };
    case ProfileActionTypes.UPDATE_CANDIDATE_EDUCATION_SUCCESS:
      return {
        ...state,
        educationLoading: false,
        educationError: null,
      };
    case ProfileActionTypes.UPDATE_CANDIDATE_EDUCATION_FAILURE:
      return {
        ...state,
        educationLoading: false,
        educationError: action.payload,
      };
    case ProfileActionTypes.DELETE_CANDIDATE_EDUCATION_REQUEST:
      return {
        ...state,
        educationLoading: false,
        educationError: null,
      };
    case ProfileActionTypes.DELETE_CANDIDATE_EDUCATION_SUCCESS:
      return {
        ...state,
        educationLoading: false,
        educationError: null,
      };
    case ProfileActionTypes.DELETE_CANDIDATE_EDUCATION_FAILURE:
      return {
        ...state,
        educationLoading: false,
        educationError: action.payload,
      };
    // skill actions
    case ProfileActionTypes.FETCH_CANDIDATE_SKILLS_REQUEST:
      return {
        ...state,
        skillLoading: true,
        skillError: null,
      };
    case ProfileActionTypes.FETCH_CANDIDATE_SKILLS_SUCCESS:
      return {
        ...state,
        skillLoading: false,
        allSkills: action.payload.data,
        skillError: null,
      };
    case ProfileActionTypes.FETCH_CANDIDATE_SKILLS_FAILURE:
      return {
        ...state,
        skillLoading: false,
        skillError: action.payload,
      };
    case ProfileActionTypes.ADD_CANDIDATE_SKILL_REQUEST:
      return {
        ...state,
        skillLoading: true,
        skillError: null,
      };
    case ProfileActionTypes.ADD_CANDIDATE_SKILL_SUCCESS:
      return {
        ...state,
        skillLoading: false,
        skillError: null,
      };
    case ProfileActionTypes.ADD_CANDIDATE_SKILL_FAILURE:
      return {
        ...state,
        skillLoading: false,
        skillError: action.payload,
      };
    case ProfileActionTypes.UPDATE_CANDIDATE_SKILL_REQUEST:
      return {
        ...state,
        skillLoading: false,
        skillError: null,
      };
    case ProfileActionTypes.UPDATE_CANDIDATE_SKILL_SUCCESS:
      return {
        ...state,
        skillLoading: false,
        skillError: null,
      };
    case ProfileActionTypes.UPDATE_CANDIDATE_SKILL_FAILURE:
      return {
        ...state,
        skillLoading: false,
        skillError: action.payload,
      };
    case ProfileActionTypes.DELETE_CANDIDATE_SKILL_REQUEST:
      return {
        ...state,
        skillLoading: false,
        skillError: null,
      };
    case ProfileActionTypes.DELETE_CANDIDATE_SKILL_SUCCESS:
      return {
        ...state,
        skillLoading: false,
        skillError: null,
      };
    case ProfileActionTypes.DELETE_CANDIDATE_SKILL_FAILURE:
      return {
        ...state,
        skillLoading: false,
        skillError: action.payload,
      };
    // language actions
    case ProfileActionTypes.FETCH_CANDIDATE_LANGUAGES_REQUEST:
      return {
        ...state,
        languageLoading: true,
        languageError: null,
      };
    case ProfileActionTypes.FETCH_CANDIDATE_LANGUAGES_SUCCESS:
      return {
        ...state,
        languageLoading: false,
        allLanguages: action.payload.data,
        languageError: null,
      };
    case ProfileActionTypes.FETCH_CANDIDATE_LANGUAGES_FAILURE:
      return {
        ...state,
        languageLoading: false,
        languageError: action.payload,
      };
    case ProfileActionTypes.ADD_CANDIDATE_LANGUAGE_REQUEST:
      return {
        ...state,
        languageLoading: true,
        languageError: null,
      };
    case ProfileActionTypes.ADD_CANDIDATE_LANGUAGE_SUCCESS:
      return {
        ...state,
        languageLoading: false,
        languageError: null,
      };
    case ProfileActionTypes.ADD_CANDIDATE_LANGUAGE_FAILURE:
      return {
        ...state,
        languageLoading: false,
        languageError: action.payload,
      };
    case ProfileActionTypes.UPDATE_CANDIDATE_LANGUAGE_REQUEST:
      return {
        ...state,
        languageLoading: false,
        languageError: null,
      };
    case ProfileActionTypes.UPDATE_CANDIDATE_LANGUAGE_SUCCESS:
      return {
        ...state,
        languageLoading: false,
        languageError: null,
      };
    case ProfileActionTypes.UPDATE_CANDIDATE_LANGUAGE_FAILURE:
      return {
        ...state,
        languageLoading: false,
        languageError: action.payload,
      };
    case ProfileActionTypes.DELETE_CANDIDATE_LANGUAGE_REQUEST:
      return {
        ...state,
        languageLoading: false,
        languageError: null,
      };
    case ProfileActionTypes.DELETE_CANDIDATE_LANGUAGE_SUCCESS:
      return {
        ...state,
        languageLoading: false,
        languageError: null,
      };
    case ProfileActionTypes.DELETE_CANDIDATE_LANGUAGE_FAILURE:
      return {
        ...state,
        languageLoading: false,
        languageError: action.payload,
      };
    // info perso
    case ProfileActionTypes.FETCH_CANDIDATE_INFO_PERSO_REQUEST:
      return {
        ...state,
        infoPersoLoading: true,
        infoPersoError: null,
        infoPersoDetails: undefined, // Reset selectedDetails on new request
      };
    case ProfileActionTypes.FETCH_CANDIDATE_INFO_PERSO_SUCCESS:
      // console.log("rrrsss", action.payload);
      return {
        ...state,
        infoPersoLoading: false,
        infoPersoDetails: action.payload,
        infoPersoError: null,
      };
    case ProfileActionTypes.FETCH_CANDIDATE_INFO_PERSO_FAILURE:
      return {
        ...state,
        infoPersoLoading: false,
        infoPersoError: action.payload,
      };
    // request file
    case ProfileActionTypes.UPDATE_CANDIDATE_PROFILE_PICTURE_REQUEST:
      return {
        ...state,
        loadingFile: true,
        errorFile: null,
        infoPersoDetails: undefined, // Reset selectedDetails on new request
      };
    case ProfileActionTypes.UPDATE_CANDIDATE_PROFILE_PICTURE_SUCCESS:
      // console.log("rrrsss", action.payload);
      return {
        ...state,
        loadingFile: false,
        errorFile: null,
      };
    case ProfileActionTypes.UPDATE_CANDIDATE_PROFILE_PICTURE_FAILURE:
      return {
        ...state,
        loadingFile: false,
        errorFile: action.payload,
      };
    // info situation
    case ProfileActionTypes.FETCH_CANDIDATE_SITUATION_REQUEST:
      return {
        ...state,
        infoSituationLoading: true,
        infoSituationError: null,
        infoSituationDetails: undefined, // Reset selectedDetails on new request
      };
    case ProfileActionTypes.FETCH_CANDIDATE_SITUATION_SUCCESS:
      // console.log("rrr", action.payload);
      return {
        ...state,
        infoSituationLoading: false,
        infoSituationDetails: action.payload.data,
        infoSituationError: null,
      };
    case ProfileActionTypes.FETCH_CANDIDATE_SITUATION_FAILURE:
      return {
        ...state,
        infoSituationLoading: false,
        infoSituationError: action.payload,
      };
    // Preference
    case ProfileActionTypes.FETCH_CANDIDATE_PREFERENCES_REQUEST:
      return {
        ...state,
        infoPreferenceLoading: true,
        infoPreferenceError: null,
        infoPreferenceDetails: undefined, // Reset selectedDetails on new request
      };
    case ProfileActionTypes.FETCH_CANDIDATE_PREFERENCES_SUCCESS:
      return {
        ...state,
        infoPreferenceLoading: false,
        infoPreferenceDetails: action.payload.data,
        infoPreferenceError: null,
      };
    case ProfileActionTypes.FETCH_CANDIDATE_PREFERENCES_FAILURE:
      return {
        ...state,
        infoPreferenceLoading: false,
        infoPreferenceError: action.payload,
      };
    default:
      return state;
  }
};

export default profileReducer;
