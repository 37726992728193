import api from "../Api";
import TokenService from "../TokenService"; // Assurez-vous d'importer votre service de token
import authHeader from "../../service/auth/auth-header";
import authService from "../../service/auth";

// Profile experience

const fetchContractTypes = async () => {
  return await api.get("api/v1/public/contract-type");
};

const fetchDomainTypes = async () => {
  return await api.get("api/v1/public/job-categories");
};

const fetchLevelTypes = async () => {
  return await api.get("api/v1/public/levels");
};

const fetchLanguageTypes = async () => {
  return await api.get("api/v1/public/language");
};

const fetchCandidateExperiences = async () => {
  try {
    // Récupérer le token d'accès
    const accessToken = TokenService.getUser();
    // console.log(" la réponse :", accessToken);
    const response = await api.get("/api/v1/candidates/experiences/me", {
      headers: authHeader(accessToken.data.id_token),
    });
    return response; // Retourne les données directement
  } catch (error) {
    console.error("Erreur lors de la récupération des expériences :", error);
    throw error; // Re-lance l'erreur pour pouvoir la gérer dans le reducer
  }
};

const addCandidateExperience = async (experienceData) => {
  const accessToken = TokenService.getUser();
  const response = await api.post(
    `/api/v1/candidates/experiences/me`,
    experienceData,
    {
      headers: authHeader(accessToken.data.id_token),
    }
  );
  return response.data; // Retourne les données du projet ajouté
};

const updateCandidateExperience = async (experienceData) => {
  // console.log("Deleting project", projectData);
  const accessToken = TokenService.getUser();
  const response = await api.put(
    "/api/v1/candidates/experiences",
    experienceData,
    {
      headers: authHeader(accessToken.data.id_token),
    }
  );
  return response.data; // Retourne les données du projet mis à jour
};

const deleteCandidateExperience = async (experienceId) => {
  const accessToken = TokenService.getUser();
  const response = await api.delete(
    `/api/v1/candidates/experiences/${experienceId}`,
    {
      headers: authHeader(accessToken.data.id_token),
    }
  );
  return response.data; // Retourne les données de la réponse après suppression
};

const fetchCandidateExperienceById = async (experienceId) => {
  const accessToken = TokenService.getUser();
  const response = await api.get(
    `/api/v1/candidates/experiences/${experienceId}`,
    {
      headers: authHeader(accessToken.data.id_token),
    }
  );
  return response.data; // Retourne les données de la réponse après suppression
};
// Formation

const fetchCandidateEducations = async () => {
  try {
    // Récupérer le token d'accès
    const accessToken = TokenService.getUser();
    const response = await api.get("/api/v1/candidates/training-candidate/me", {
      headers: authHeader(accessToken.data.id_token),
    });
    return response; // Retourne les données directement
  } catch (error) {
    console.error("Erreur lors de la récupération des expériences :", error);
    throw error; // Re-lance l'erreur pour pouvoir la gérer dans le reducer
  }
};

const addCandidateEducation = async (educationData) => {
  const accessToken = TokenService.getUser();
  const response = await api.post(
    `/api/v1/candidates/training-candidate/me`,
    educationData,
    {
      headers: authHeader(accessToken.data.id_token),
    }
  );
  return response.data; // Retourne les données du projet ajouté
};

const updateCandidateEducation = async (educationData) => {
  // console.log("Deleting project", projectData);
  const accessToken = TokenService.getUser();
  const response = await api.put(
    "/api/v1/candidates/training-candidate",
    educationData,
    {
      headers: authHeader(accessToken.data.id_token),
    }
  );
  return response.data; // Retourne les données du projet mis à jour
};

const deleteCandidateEducation = async (educationId) => {
  const accessToken = TokenService.getUser();
  const response = await api.delete(
    `/api/v1/candidates/training-candidate/${educationId}`,
    {
      headers: authHeader(accessToken.data.id_token),
    }
  );
  return response.data; // Retourne les données de la réponse après suppression
};

const fetchCandidateEducationById = async (educationId) => {
  const accessToken = TokenService.getUser();
  const response = await api.get(
    `/api/v1/candidates/training-candidate/${educationId}`,
    {
      headers: authHeader(accessToken.data.id_token),
    }
  );
  return response.data; // Retourne les données de la réponse après suppression
};

// Skills

const fetchCandidateSkills = async () => {
  try {
    // Récupérer le token d'accès
    const accessToken = TokenService.getUser();
    const response = await api.get("/api/v1/candidates/skills/me", {
      headers: authHeader(accessToken.data.id_token),
    });
    return response; // Retourne les données directement
  } catch (error) {
    console.error("Erreur lors de la récupération des compétences :", error);
    throw error; // Re-lance l'erreur pour pouvoir la gérer dans le reducer
  }
};

const addCandidateSkill = async (skillData) => {
  const accessToken = TokenService.getUser();
  const response = await api.post(`/api/v1/candidates/skills/me`, skillData, {
    headers: authHeader(accessToken.data.id_token),
  });
  return response.data; // Retourne les données du projet ajouté
};

const updateCandidateSkill = async (skillData) => {
  // console.log("Deleting project", projectData);
  const accessToken = TokenService.getUser();
  const response = await api.put("/api/v1/candidates/skills", skillData, {
    headers: authHeader(accessToken.data.id_token),
  });
  return response.data; // Retourne les données du projet mis à jour
};

const deleteCandidateSkill = async (skillId) => {
  const accessToken = TokenService.getUser();
  const response = await api.delete(`/api/v1/candidates/skills/${skillId}`, {
    headers: authHeader(accessToken.data.id_token),
  });
  return response.data; // Retourne les données de la réponse après suppression
};

const fetchCandidateSkillById = async (skillId) => {
  const accessToken = TokenService.getUser();
  const response = await api.get(`/api/v1/candidates/skills/${skillId}`, {
    headers: authHeader(accessToken.data.id_token),
  });
  return response.data; // Retourne les données de la réponse après suppression
};

// language-candidate

const fetchCandidatelanguages = async () => {
  try {
    // Récupérer le token d'accès
    const accessToken = TokenService.getUser();
    const response = await api.get("/api/v1/candidates/language-candidate/me", {
      headers: authHeader(accessToken.data.id_token),
    });
    return response; // Retourne les données directement
  } catch (error) {
    console.error("Erreur lors de la récupération des langues :", error);
    throw error; // Re-lance l'erreur pour pouvoir la gérer dans le reducer
  }
};

const addCandidateLanguage = async (languageData) => {
  const accessToken = TokenService.getUser();
  const response = await api.post(
    `/api/v1/candidates/language-candidate/me`,
    languageData,
    {
      headers: authHeader(accessToken.data.id_token),
    }
  );
  return response.data; // Retourne les données du projet ajouté
};

const updateCandidateLanguage = async (languageData) => {
  // console.log("Deleting project", projectData);
  const accessToken = TokenService.getUser();
  const response = await api.put(
    "/api/v1/candidates/language-candidate",
    languageData,
    {
      headers: authHeader(accessToken.data.id_token),
    }
  );
  return response.data; // Retourne les données du projet mis à jour
};

const deleteCandidateLanguage = async (languageId) => {
  const accessToken = TokenService.getUser();
  const response = await api.delete(
    `/api/v1/candidates/language-candidate/${languageId}`,
    {
      headers: authHeader(accessToken.data.id_token),
    }
  );
  return response.data; // Retourne les données de la réponse après suppression
};

const fetchCandidateLanguageById = async (languageId) => {
  const accessToken = TokenService.getUser();
  const response = await api.get(
    `/api/v1/candidates/language-candidate/${languageId}`,
    {
      headers: authHeader(accessToken.data.id_token),
    }
  );
  return response.data; // Retourne les données de la réponse après suppression
};

//Info Perso

const fetchCandidateInfoPerso = async () => {
  try {
    // Récupérer le token d'accès
    const accessToken = TokenService.getUser();
    if (accessToken === null || accessToken === undefined) {
      // Rediriger l'utilisateur vers la page de connexion
      console.log("Erreur 401 détectée : déconnexion automatique");
      // Supprimer le token et déconnecter l'utilisateur
      authService.signOut();
      window.location.href = "/";
    }
    const response = await api.get("/api/v1/candidates/profile", {
      headers: authHeader(accessToken.data.id_token),
    });
    return response; // Retourne les données directement
  } catch (error) {
    console.error(
      "Erreur lors de la récupération des informations personnelles :",
      error
    );
    throw error; // Re-lance l'erreur pour pouvoir la gérer dans le reducer
  }
};

const updateCandidateInfoPerso = async (infoPersoData) => {
  const accessToken = TokenService.getUser();
  const response = await api.put("/api/v1/candidates/profile", infoPersoData, {
    headers: authHeader(accessToken.data.id_token),
  });
  return response.data; // Retourne les données du projet mis à jour
};

// update profile picture

const updateCandidateProfilePicture = async (pictureData) => {
  if (pictureData) {
    try {
      const accessToken = TokenService.getUser();
      const formData = new FormData();
      formData.append("profilePicture", pictureData);
      // console.log("updateProfileform", formData);
      const response = await api.put(
        "/api/v1/candidates/profile/picture",
        formData,
        {
          headers: {
            ...authHeader(accessToken.data.id_token),
            "Content-Type": "multipart/form-data",
          },
        }
      );

      return response.data; // Retourne les données après la mise à jour
    } catch (error) {
      console.error(
        "Erreur lors de la mise à jour de l'image de profil :",
        error
      );
      throw error;
    }
  }
};

// update cv
const uploadProfileCv = async (cvData) => {
  if (cvData) {
    try {
      const accessToken = TokenService.getUser();
      const formData = new FormData();

      // Ajout des champs requis dans le FormData
      formData.append("file", cvData); // Le fichier
      formData.append("documentType", "CV"); // Clé supplémentaire avec "CV"

      const response = await api.put(
        "/api/v1/candidates/profile/documents",
        formData,
        {
          headers: {
            ...authHeader(accessToken.data.id_token),
            "Content-Type": "multipart/form-data",
          },
        }
      );

      return response.data; // Retourne les données après la mise à jour
    } catch (error) {
      console.error("Erreur lors de la mise à jour du Cv :", error);
      throw error;
    }
  }
};

// update certificate
const uploadProfileCertificate = async (certificatData) => {
  if (certificatData) {
    try {
      const accessToken = TokenService.getUser();
      const formData = new FormData();

      // Ajout des champs requis dans le FormData
      formData.append("file", certificatData); // Le fichier
      formData.append("documentType", "CERTIFICATE"); // Clé supplémentaire avec "CV"

      const response = await api.put(
        "/api/v1/candidates/profile/documents",
        formData,
        {
          headers: {
            ...authHeader(accessToken.data.id_token),
            "Content-Type": "multipart/form-data",
          },
        }
      );

      return response.data; // Retourne les données après la mise à jour
    } catch (error) {
      console.error("Erreur lors de la mise à jour du Certificat :", error);
      throw error;
    }
  }
};

// Ma situatio

const updateCandidateInfoSituation = async (infoSituationData) => {
  const accessToken = TokenService.getUser();
  const response = await api.put(
    "/api/v1/candidates/current-status",
    infoSituationData,
    {
      headers: authHeader(accessToken.data.id_token),
    }
  );
  return response.data; // Retourne les données du projet mis à jour
};

const fetchCandidateInfoSituation = async () => {
  try {
    // Récupérer le token d'accès
    const accessToken = TokenService.getUser();
    const response = await api.get("/api/v1/candidates/current-status", {
      headers: authHeader(accessToken.data.id_token),
    });
    return response; // Retourne les données directement
  } catch (error) {
    console.error("Erreur lors de la récupération des informations :", error);
    throw error; // Re-lance l'erreur pour pouvoir la gérer dans le reducer
  }
};

// Mes Preference

const updateCandidateInfoPreferences = async (infoPreferencesData) => {
  const accessToken = TokenService.getUser();
  const response = await api.put(
    "/api/v1/candidates/account-preferences",
    infoPreferencesData,
    {
      headers: authHeader(accessToken.data.id_token),
    }
  );
  return response.data; // Retourne les données du projet mis à jour
};

const fetchCandidateInfoPreferences = async () => {
  try {
    // Récupérer le token d'accès
    const accessToken = TokenService.getUser();
    const response = await api.get("/api/v1/candidates/account-preferences", {
      headers: authHeader(accessToken.data.id_token),
    });
    return response; // Retourne les données directement
  } catch (error) {
    console.error("Erreur lors de la récupération des informations :", error);
    throw error; // Re-lance l'erreur pour pouvoir la gérer dans le reducer
  }
};

// Exporter les services
const ProfileService = {
  fetchCandidateInfoPreferences,
  updateCandidateInfoPreferences,
  updateCandidateInfoSituation,
  fetchCandidateInfoSituation,
  fetchDomainTypes,
  fetchLanguageTypes,
  fetchLevelTypes,
  fetchCandidateExperiences,
  addCandidateExperience,
  updateCandidateExperience,
  deleteCandidateExperience,
  fetchContractTypes,
  fetchCandidateExperienceById,
  fetchCandidateEducations,
  addCandidateEducation,
  updateCandidateEducation,
  deleteCandidateEducation,
  fetchCandidateEducationById,
  fetchCandidateSkills,
  addCandidateSkill,
  updateCandidateSkill,
  deleteCandidateSkill,
  fetchCandidateSkillById,
  fetchCandidatelanguages,
  addCandidateLanguage,
  updateCandidateLanguage,
  deleteCandidateLanguage,
  fetchCandidateLanguageById,
  fetchCandidateInfoPerso,
  updateCandidateInfoPerso,
  updateCandidateProfilePicture,
  uploadProfileCv,
  uploadProfileCertificate,
};

export default ProfileService;
