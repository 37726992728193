import * as TestActionTypes from "../types";

const initialState = {
  testQuestions: [],
  testResult: null,
  loading: false,
  error: undefined,
};

const testReducer = (state = initialState, action) => {
  switch (action.type) {
    case TestActionTypes.FETCH_CANDIDATE_TEST_REQUEST:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case TestActionTypes.FETCH_CANDIDATE_TEST_SUCCESS:
      // console.log("rrr", action.payload.data);
      return {
        ...state,
        loading: false,
        testQuestions: action.payload.data,
        error: null,
      };

    case TestActionTypes.FETCH_CANDIDATE_TEST_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    case TestActionTypes.BEGIN_CANDIDATE_TEST_REQUEST:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case TestActionTypes.BEGIN_CANDIDATE_TEST_SUCCESS:
      return {
        ...state,
        loading: false,
        error: null,
      };

    case TestActionTypes.BEGIN_CANDIDATE_TEST_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    case TestActionTypes.SUBMIT_CANDIDATE_TEST_REQUEST:
      return {
        ...state,
        loading: true,
        error: null,
      };

    case TestActionTypes.SUBMIT_CANDIDATE_TEST_SUCCESS:
      // console.log("rrr", action.payload);
      return {
        ...state,
        loading: false,
        testResult: action.payload,
        error: null,
      };

    case TestActionTypes.SUBMIT_CANDIDATE_TEST_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    case TestActionTypes.RESULT_CANDIDATE_TEST_REQUEST:
      return {
        ...state,
        loading: false,
        error: null,
      };

    case TestActionTypes.RESULT_CANDIDATE_TEST_SUCCESS:
      // console.log("rrr", action.payload.data);
      return {
        ...state,
        loading: false,
        testResult: action.payload.data,
        error: null,
      };

    case TestActionTypes.RESULT_CANDIDATE_TEST_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    default:
      return state;
  }
};

export default testReducer;
