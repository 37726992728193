// test types.js
export const FETCH_CANDIDATE_TEST_REQUEST = "FETCH_CANDIDATE_TEST_REQUEST";
export const FETCH_CANDIDATE_TEST_SUCCESS = "FETCH_CANDIDATE_TEST_SUCCESS";
export const FETCH_CANDIDATE_TEST_FAILURE = "FETCH_CANDIDATE_TEST_FAILURE";

export const BEGIN_CANDIDATE_TEST_REQUEST = "BEGIN_CANDIDATE_TEST_REQUEST";
export const BEGIN_CANDIDATE_TEST_SUCCESS = "BEGIN_CANDIDATE_TEST_SUCCESS";
export const BEGIN_CANDIDATE_TEST_FAILURE = "BEGIN_CANDIDATE_TEST_FAILURE";

export const SUBMIT_CANDIDATE_TEST_REQUEST = "SUBMIT_CANDIDATE_TEST_REQUEST";
export const SUBMIT_CANDIDATE_TEST_SUCCESS = "SUBMIT_CANDIDATE_TEST_SUCCESS";
export const SUBMIT_CANDIDATE_TEST_FAILURE = "SUBMIT_CANDIDATE_TEST_FAILURE";

export const RESULT_CANDIDATE_TEST_REQUEST = "RESULT_CANDIDATE_TEST_REQUEST";
export const RESULT_CANDIDATE_TEST_SUCCESS = "RESULT_CANDIDATE_TEST_SUCCESS";
export const RESULT_CANDIDATE_TEST_FAILURE = "RESULT_CANDIDATE_TEST_FAILURE";
