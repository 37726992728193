export const SELECT_PROFILE_REQUEST = "SELECT_PROFILE_REQUEST";
export const SELECT_PROFILE_SUCCESS = "SELECT_PROFILE_SUCCESS";
export const SELECT_PROFILE_FAILURE = "SELECT_PROFILE_FAILURE";

export const FETCH_CONTRACT_TYPES_REQUEST = "FETCH_CONTRACT_TYPES_REQUEST";
export const FETCH_CONTRACT_TYPES_SUCCESS = "FETCH_CONTRACT_TYPES_SUCCESS";
export const FETCH_CONTRACT_TYPES_FAILURE = "FETCH_CONTRACT_TYPES_FAILURE";

//Domain

export const FETCH_DOMAIN_TYPES_REQUEST = "FETCH_DOMAIN_TYPES_REQUEST";
export const FETCH_DOMAIN_TYPES_SUCCESS = "FETCH_DOMAIN_TYPES_SUCCESS";
export const FETCH_DOMAIN_TYPES_FAILURE = "FETCH_DOMAIN_TYPES_FAILURE";

// Candidate situation
export const FETCH_CANDIDATE_SITUATION_REQUEST =
  "FETCH_CANDIDATE_SITUATION_REQUEST";
export const FETCH_CANDIDATE_SITUATION_SUCCESS =
  "FETCH_CANDIDATE_SITUATION_SUCCESS";
export const FETCH_CANDIDATE_SITUATION_FAILURE =
  "FETCH_CANDIDATE_SITUATION_FAILURE";

// update candidate situation
export const UPDATE_CANDIDATE_SITUATION_REQUEST =
  "UPDATE_CANDIDATE_SITUATION_REQUEST";
export const UPDATE_CANDIDATE_SITUATION_SUCCESS =
  "UPDATE_CANDIDATE_SITUATION_SUCCESS";
export const UPDATE_CANDIDATE_SITUATION_FAILURE =
  "UPDATE_CANDIDATE_SITUATION_FAILURE";

// preference

export const FETCH_CANDIDATE_PREFERENCES_REQUEST =
  "FETCH_CANDIDATE_PREFERENCES_REQUEST";
export const FETCH_CANDIDATE_PREFERENCES_SUCCESS =
  "FETCH_CANDIDATE_PREFERENCES_SUCCESS";
export const FETCH_CANDIDATE_PREFERENCES_FAILURE =
  "FETCH_CANDIDATE_PREFERENCES_FAILURE";

// update preference

export const UPDATE_CANDIDATE_PREFERENCES_REQUEST =
  "UPDATE_CANDIDATE_PREFERENCES_REQUEST";
export const UPDATE_CANDIDATE_PREFERENCES_SUCCESS =
  "UPDATE_CANDIDATE_PREFERENCES_SUCCESS";
export const UPDATE_CANDIDATE_PREFERENCES_FAILURE =
  "UPDATE_CANDIDATE_PREFERENCES_FAILURE";

// Experience
export const FETCH_LEVEL_TYPES_REQUEST = "FETCH_LEVEL_TYPES_REQUEST";
export const FETCH_LEVEL_TYPES_SUCCESS = "FETCH_LEVEL_TYPES_SUCCESS";
export const FETCH_LEVEL_TYPES_FAILURE = "FETCH_LEVEL_TYPES_FAILURE";

export const FETCH_LANGUAGE_TYPES_REQUEST = "FETCH_LANGUAGE_TYPES_REQUEST";
export const FETCH_LANGUAGE_TYPES_SUCCESS = "FETCH_LANGUAGE_TYPES_SUCCESS";
export const FETCH_LANGUAGE_TYPES_FAILURE = "FETCH_LANGUAGE_TYPES_FAILURE";

// InfoPerso

export const FETCH_CANDIDATE_INFO_PERSO_REQUEST =
  "FETCH_CANDIDATE_INFO_PERSO_REQUEST";
export const FETCH_CANDIDATE_INFO_PERSO_SUCCESS =
  "FETCH_CANDIDATE_INFO_PERSO_SUCCESS";
export const FETCH_CANDIDATE_INFO_PERSO_FAILURE =
  "FETCH_CANDIDATE_INFO_PERSO_FAILURE";

export const UPDATE_CANDIDATE_INFO_PERSO_REQUEST =
  "UPDATE_CANDIDATE_INFO_PERSO_REQUEST";
export const UPDATE_CANDIDATE_INFO_PERSO_SUCCESS =
  "UPDATE_CANDIDATE_INFO_PERSO_SUCCESS";
export const UPDATE_CANDIDATE_INFO_PERSO_FAILURE =
  "UPDATE_CANDIDATE_INFO_PERSO_FAILURE";

// Profile Picture

export const UPDATE_CANDIDATE_PROFILE_PICTURE_REQUEST =
  "UPDATE_CANDIDATE_PROFILE_PICTURE_REQUEST";
export const UPDATE_CANDIDATE_PROFILE_PICTURE_SUCCESS =
  "UPDATE_CANDIDATE_PROFILE_PICTURE_SUCCESS";
export const UPDATE_CANDIDATE_PROFILE_PICTURE_FAILURE =
  "UPDATE_CANDIDATE_PROFILE_PICTURE_FAILURE";

// Experience types.js
export const FETCH_CANDIDATE_EXPERIENCE_REQUEST =
  "FETCH_CANDIDATE_EXPERIENCE_REQUEST";
export const FETCH_CANDIDATE_EXPERIENCE_SUCCESS =
  "FETCH_CANDIDATE_EXPERIENCE_SUCCESS";
export const FETCH_CANDIDATE_EXPERIENCE_FAILURE =
  "FETCH_CANDIDATE_EXPERIENCE_FAILURE";

export const ADD_CANDIDATE_EXPERIENCE_REQUEST =
  "ADD_CANDIDATE_EXPERIENCE_REQUEST";
export const ADD_CANDIDATE_EXPERIENCE_SUCCESS =
  "ADD_CANDIDATE_EXPERIENCE_SUCCESS";
export const ADD_CANDIDATE_EXPERIENCE_FAILURE =
  "ADD_CANDIDATE_EXPERIENCE_FAILURE";

export const UPDATE_CANDIDATE_EXPERIENCE_REQUEST =
  "UPDATE_CANDIDATE_EXPERIENCE_REQUEST";
export const UPDATE_CANDIDATE_EXPERIENCE_SUCCESS =
  "UPDATE_CANDIDATE_EXPERIENCE_SUCCESS";
export const UPDATE_CANDIDATE_EXPERIENCE_FAILURE =
  "UPDATE_CANDIDATE_EXPERIENCE_FAILURE";

export const DELETE_CANDIDATE_EXPERIENCE_REQUEST =
  "DELETE_CANDIDATE_EXPERIENCE_REQUEST";
export const DELETE_CANDIDATE_EXPERIENCE_SUCCESS =
  "DELETE_CANDIDATE_EXPERIENCE_SUCCESS";
export const DELETE_CANDIDATE_EXPERIENCE_FAILURE =
  "DELETE_CANDIDATE_EXPERIENCE_FAILURE";

// Education types.js

export const FETCH_CANDIDATE_EDUCATION_REQUEST =
  "FETCH_CANDIDATE_EDUCATION_REQUEST";
export const FETCH_CANDIDATE_EDUCATION_SUCCESS =
  "FETCH_CANDIDATE_EDUCATION_SUCCESS";
export const FETCH_CANDIDATE_EDUCATION_FAILURE =
  "FETCH_CANDIDATE_EDUCATION_FAILURE";

export const ADD_CANDIDATE_EDUCATION_REQUEST =
  "ADD_CANDIDATE_EDUCATION_REQUEST";

export const ADD_CANDIDATE_EDUCATION_SUCCESS =
  "ADD_CANDIDATE_EDUCATION_SUCCESS";

export const ADD_CANDIDATE_EDUCATION_FAILURE =
  "ADD_CANDIDATE_EDUCATION_FAILURE";

export const UPDATE_CANDIDATE_EDUCATION_REQUEST =
  "UPDATE_CANDIDATE_EDUCATION_REQUEST";

export const UPDATE_CANDIDATE_EDUCATION_SUCCESS =
  "UPDATE_CANDIDATE_EDUCATION_SUCCESS";

export const UPDATE_CANDIDATE_EDUCATION_FAILURE =
  "UPDATE_CANDIDATE_EDUCATION_FAILURE";

export const DELETE_CANDIDATE_EDUCATION_REQUEST =
  "DELETE_CANDIDATE_EDUCATION_REQUEST";

export const DELETE_CANDIDATE_EDUCATION_SUCCESS =
  "DELETE_CANDIDATE_EDUCATION_SUCCESS";

export const DELETE_CANDIDATE_EDUCATION_FAILURE =
  "DELETE_CANDIDATE_EDUCATION_FAILURE";

// Skill types.js

export const FETCH_CANDIDATE_SKILLS_REQUEST = "FETCH_CANDIDATE_SKILLS_REQUEST";
export const FETCH_CANDIDATE_SKILLS_SUCCESS = "FETCH_CANDIDATE_SKILLS_SUCCESS";
export const FETCH_CANDIDATE_SKILLS_FAILURE = "FETCH_CANDIDATE_SKILLS_FAILURE";

export const ADD_CANDIDATE_SKILL_REQUEST = "ADD_CANDIDATE_SKILL_REQUEST";

export const ADD_CANDIDATE_SKILL_SUCCESS = "ADD_CANDIDATE_SKILL_SUCCESS";

export const ADD_CANDIDATE_SKILL_FAILURE = "ADD_CANDIDATE_SKILL_FAILURE";

export const UPDATE_CANDIDATE_SKILL_REQUEST = "UPDATE_CANDIDATE_SKILL_REQUEST";

export const UPDATE_CANDIDATE_SKILL_SUCCESS = "UPDATE_CANDIDATE_SKILL_SUCCESS";

export const UPDATE_CANDIDATE_SKILL_FAILURE = "UPDATE_CANDIDATE_SKILL_FAILURE";

export const DELETE_CANDIDATE_SKILL_REQUEST = "DELETE_CANDIDATE_SKILL_REQUEST";

export const DELETE_CANDIDATE_SKILL_SUCCESS = "DELETE_CANDIDATE_SKILL_SUCCESS";

export const DELETE_CANDIDATE_SKILL_FAILURE = "DELETE_CANDIDATE_SKILL_FAILURE";

// Language types.js

export const FETCH_CANDIDATE_LANGUAGES_REQUEST =
  "FETCH_CANDIDATE_LANGUAGES_REQUEST";
export const FETCH_CANDIDATE_LANGUAGES_SUCCESS =
  "FETCH_CANDIDATE_LANGUAGES_SUCCESS";
export const FETCH_CANDIDATE_LANGUAGES_FAILURE =
  "FETCH_CANDIDATE_LANGUAGES_FAILURE";

export const ADD_CANDIDATE_LANGUAGE_REQUEST = "ADD_CANDIDATE_LANGUAGE_REQUEST";

export const ADD_CANDIDATE_LANGUAGE_SUCCESS = "ADD_CANDIDATE_LANGUAGE_SUCCESS";

export const ADD_CANDIDATE_LANGUAGE_FAILURE = "ADD_CANDIDATE_LANGUAGE_FAILURE";

export const UPDATE_CANDIDATE_LANGUAGE_REQUEST =
  "UPDATE_CANDIDATE_LANGUAGE_REQUEST";

export const UPDATE_CANDIDATE_LANGUAGE_SUCCESS =
  "UPDATE_CANDIDATE_LANGUAGE_SUCCESS";

export const UPDATE_CANDIDATE_LANGUAGE_FAILURE =
  "UPDATE_CANDIDATE_LANGUAGE_FAILURE";

export const DELETE_CANDIDATE_LANGUAGE_REQUEST =
  "DELETE_CANDIDATE_LANGUAGE_REQUEST";

export const DELETE_CANDIDATE_LANGUAGE_SUCCESS =
  "DELETE_CANDIDATE_LANGUAGE_SUCCESS";

export const DELETE_CANDIDATE_LANGUAGE_FAILURE =
  "DELETE_CANDIDATE_LANGUAGE_FAILURE";

// Hobbies types

export const FETCH_CANDIDATE_HOBBIES_REQUEST =
  "FETCH_CANDIDATE_HOBBIES_REQUEST";
export const FETCH_CANDIDATE_HOBBIES_SUCCESS =
  "FETCH_CANDIDATE_HOBBIES_SUCCESS";
export const FETCH_CANDIDATE_HOBBIES_FAILURE =
  "FETCH_CANDIDATE_HOBBIES_FAILURE";

export const ADD_CANDIDATE_HOBBY_REQUEST = "ADD_CANDIDATE_HOBBY_REQUEST";

export const ADD_CANDIDATE_HOBBY_SUCCESS = "ADD_CANDIDATE_HOBBY_SUCCESS";

export const ADD_CANDIDATE_HOBBY_FAILURE = "ADD_CANDIDATE_HOBBY_FAILURE";

export const UPDATE_CANDIDATE_HOBBY_REQUEST = "UPDATE_CANDIDATE_HOBBY_REQUEST";

export const UPDATE_CANDIDATE_HOBBY_SUCCESS = "UPDATE_CANDIDATE_HOBBY_SUCCESS";

export const UPDATE_CANDIDATE_HOBBY_FAILURE = "UPDATE_CANDIDATE_HOBBY_FAILURE";

export const DELETE_CANDIDATE_HOBBY_REQUEST = "DELETE_CANDIDATE_HOBBY_REQUEST";

export const DELETE_CANDIDATE_HOBBY_SUCCESS = "DELETE_CANDIDATE_HOBBY_SUCCESS";

export const DELETE_CANDIDATE_HOBBY_FAILURE = "DELETE_CANDIDATE_HOBBY_FAILURE";
