import api from "../Api";

const fetchCandidateQuestionTest = async (token) => {
  try {
    const url = `/api/v1/public/candidates/technical-test/access-token?token=${token}`;
    const response = await api.get(url);
    return response; // Retourne les données directement
  } catch (error) {
    console.error("Erreur lors de la récupération des expériences :", error);
    throw error; // Re-lance l'erreur pour pouvoir la gérer dans le reducer
  }
};

const beginCandidateTest = async (token) => {
  const url = `/api/v1/public/candidates/technical-test/${
    token ? `start?token=${token}` : ""
  }`;
  const response = await api.patch(url);
  return response.data; // Retourne les données du projet ajouté
};

const submitCandidateTest = async (token, data) => {
  const url = `/api/v1/public/candidates/technical-test/correction/${
    token ? `access-token?token=${token}` : ""
  }`;
  const response = await api.post(url, data);
  return response.data; // Retourne les données du projet mis à jour
};

const resultCandidateTest = async () => {
  const url = `/api/v1/public/candidates/technical-test/`;
  const response = await api.get(url);
  return response.data; // Retourne les données de la réponse après suppression
};

// Exporter les services
const TestService = {
  fetchCandidateQuestionTest,
  beginCandidateTest,
  submitCandidateTest,
  resultCandidateTest,
};

export default TestService;
