import TestService from "../../../service/test";
import * as TestActionTypes from "../types";

//TEST actions

export const fetchCandidateQuestionTest = (accessToken) => {
  return async (dispatch) => {
    dispatch({ type: TestActionTypes.FETCH_CANDIDATE_TEST_REQUEST });
    try {
      const response = await TestService.fetchCandidateQuestionTest(
        accessToken
      );
      //   console.log("rrrsssssss", response);
      if (response && response.data && response.status === 200) {
        dispatch({
          type: TestActionTypes.FETCH_CANDIDATE_TEST_SUCCESS,
          payload: response.data,
        });
      }
    } catch (error) {
      dispatch({
        type: TestActionTypes.FETCH_CANDIDATE_TEST_FAILURE,
        payload: error.message,
      });
    }
  };
};

export const beginCandidateTest = (accessToken) => {
  return async (dispatch) => {
    dispatch({ type: TestActionTypes.BEGIN_CANDIDATE_TEST_REQUEST });
    try {
      const response = await TestService.beginCandidateTest(accessToken);
      dispatch({
        type: TestActionTypes.BEGIN_CANDIDATE_TEST_SUCCESS,
        payload: response.data,
      });
    } catch (error) {
      dispatch({
        type: TestActionTypes.BEGIN_CANDIDATE_TEST_FAILURE,
        payload: error.message,
      });
    }
  };
};

export const submitCandidateTest = (accessToken, data) => {
  return async (dispatch) => {
    dispatch({ type: TestActionTypes.SUBMIT_CANDIDATE_TEST_REQUEST });
    try {
      const response = await TestService.submitCandidateTest(accessToken, data);
      if (response && response.data && response.statusCode === 200) {
        // console.log("rrrbggggsssssss", response);
        dispatch({
          type: TestActionTypes.SUBMIT_CANDIDATE_TEST_SUCCESS,
          payload: response.data,
        });
      }
    } catch (error) {
      dispatch({
        type: TestActionTypes.SUBMIT_CANDIDATE_TEST_FAILURE,
        payload: error.message,
      });
    }
  };
};

export const resultCandidateTest = () => {
  return async (dispatch) => {
    dispatch({ type: TestActionTypes.RESULT_CANDIDATE_TEST_REQUEST });
    try {
      const response = await TestService.resultCandidateTest();
      dispatch({
        type: TestActionTypes.RESULT_CANDIDATE_TEST_SUCCESS,
        payload: response.data,
      });
    } catch (error) {
      dispatch({
        type: TestActionTypes.RESULT_CANDIDATE_TEST_FAILURE,
        payload: error.message,
      });
    }
  };
};
