import React from "react";
import Section from "./Section";
import TestGridContent from "./TestGridContent";

const TestGrid = () => {
  document.title = "Liste des tests techniques | RecruTech";
  return (
    <React.Fragment>
      <Section />
      <TestGridContent />
    </React.Fragment>
  );
};

export default TestGrid;
