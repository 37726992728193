import React from "react";
import { Row, Col } from "reactstrap";
import { fr } from "date-fns/locale"; // Importez la locale française
import { format } from "date-fns"; // Importer la fonction de formatage

const BlogColumn = ({ selectedDetails }) => {
  // Assurez-vous que selectedDetails est défini et non vide
  if (!selectedDetails) {
    return null; // ou un message d'erreur ou un composant de chargement
  }

  const authorImage =
    selectedDetails.authorImage ||
    "https://res.cloudinary.com/dryskp7hv/image/upload/v1718890903/189632_pu5gij.png";

  // Formater la date de début avec une vérification préalable
  const formattedStartDate =
    selectedDetails.startDate && !isNaN(new Date(selectedDetails.startDate))
      ? format(new Date(selectedDetails.startDate), "dd MMMM yyyy", {
          locale: fr,
        })
      : "Date non disponible";

  // Vérifiez si learningObjectives contient des balises ou est du texte brut
  const descriptionHTML = selectedDetails.learningObjectives || "";
  let listItems = [];

  if (/<\/?[a-z][\s\S]*>/i.test(descriptionHTML)) {
    // Contient du HTML
    const parser = new DOMParser();
    const parsedHTML = parser.parseFromString(descriptionHTML, "text/html");

    // Vérifiez d'abord s'il y a des éléments <li>
    const listElements = Array.from(parsedHTML.querySelectorAll("li"));

    if (listElements.length > 0) {
      // Si des <li> existent, extrayez leur contenu
      listItems = listElements.map((item) => item.innerHTML.trim());
    } else {
      // Sinon, gérez les autres balises HTML comme <p> et supprimez les balises vides
      const paragraphs = Array.from(parsedHTML.querySelectorAll("p")).filter(
        (p) => p.textContent.trim() !== "" // Supprimez les <p> vides
      );

      listItems = paragraphs.map((p) => p.textContent.trim());
    }
  } else {
    // Texte brut, transformez-le en une liste avec un seul élément
    listItems = [descriptionHTML.trim()];
  }

  return (
    <React.Fragment>
      <ul className="list-inline mb-0 mt-3 text-muted">
        <li className="list-inline-item">
          <div className="d-flex align-items-center">
            <div className="flex-shrink-0">
              <img
                src={authorImage}
                alt=""
                className="avatar-sm rounded-circle"
                style={{
                  border: "3px solid #28a745", // Couleur verte avec une largeur de 3px
                  padding: "2px", // Espacement entre l'image et la bordure
                  borderRadius: "50%", // Assurez-vous que la bordure suit la forme circulaire de l'image
                }}
              />
            </div>
            <div className="ms-3">
              <h6 className="mb-0">{selectedDetails.authorName}</h6>
            </div>
          </div>
        </li>
        <li className="list-inline-item">
          <div className="d-flex align-items-center">
            <div className="flex-shrink-0">
              <i className="uil uil-calendar-alt"></i>
            </div>
            <div className="ms-2">
              <p className="mb-0">{formattedStartDate}</p>
            </div>
          </div>
        </li>
      </ul>
      <div className="mt-4">
        <div className="sd-title mb-3">
          <h6 className="fs-16">Ce que vous apprendrez</h6>
        </div>

        {/* Affichage des descriptions en colonnes de deux */}
        <Row>
          {listItems.map((item, index) => (
            <Col md={6} key={index} className="mb-4">
              <p className="text-muted fs-14">
                <i className="uil uil-check"></i>
                <span dangerouslySetInnerHTML={{ __html: item }} />
              </p>
            </Col>
          ))}
        </Row>
      </div>
    </React.Fragment>
  );
};

export default BlogColumn;
