import React, { useState, useEffect } from "react";
import {
  Container,
  Card,
  CardBody,
  Nav,
  NavItem,
  NavLink,
  TabContent,
  TabPane,
  Table,
} from "reactstrap";
import "./Services.css"; // Assurez-vous d'importer le fichier CSS
import { submitCandidateTest } from "../../../redux/test/action";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { Pie } from "react-chartjs-2";
import { Chart, ArcElement, Legend, Tooltip } from "chart.js";
import classnames from "classnames";
import { Skeleton } from "antd";
import Lottie from "react-lottie";

import * as errorAnimationData from "../../../../src/assets/error.json"; // Animation pour l'erreur

Chart.register(ArcElement, Legend, Tooltip);

const ServicePage = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const [activeTab, setActiveTab] = useState("1");

  const {
    access_token,
    responseData,
    startTime,
    endTime,
    minutes,
    seconds,
    totalQuestions,
    duration,
    evaluate,
  } = location.state || {};

  const dispatch = useDispatch();
  const { testResult, loading, error } = useSelector(
    (state) => state.testReducer
  );

  useEffect(() => {
    if (!location.state) {
      navigate("/");
    } else {
      // Dispatch the action
      dispatch(submitCandidateTest(access_token, responseData));
    }
  }, [dispatch, access_token, responseData, location.state, navigate]);

  const toggleTab = (tab) => {
    if (activeTab !== tab) setActiveTab(tab);
  };

  // const formattedStartTime = new Intl.DateTimeFormat("fr-FR", {
  //   dateStyle: "full",
  //   timeStyle: "long",
  // }).format(new Date(startTime));

  // const formattedEndTime = new Intl.DateTimeFormat("fr-FR", {
  //   dateStyle: "full",
  //   timeStyle: "long",
  // }).format(new Date(endTime));

  const pieData = {
    labels: ["Correctes", "Incorrectes"],
    datasets: [
      {
        data: [
          testResult?.results.filter((r) => r.answerStatus === "CORRECT")
            .length || 0,
          testResult?.results.filter((r) => r.answerStatus !== "CORRECT")
            .length || 0,
        ],
        backgroundColor: ["#31abdf", "#fac96f"],
      },
    ],
  };

  const pieOptions = {
    plugins: {
      legend: {
        position: "bottom",
      },
    },
  };

  const getStatusIcon = (isCorrect) => {
    return isCorrect ? (
      <span style={{ color: "green" }}>✔️</span>
    ) : (
      <span style={{ color: "red" }}>❌</span>
    );
  };
  const getBadgeClass = (decision) => {
    switch (decision) {
      case "SUCCESS":
        return "badge bg-success-subtle text-success fs-16 me-2";
      case "FAILED":
        return "badge bg-danger-subtle text-danger fs-16 me-2";
      default:
        return "badge bg-secondary-subtle text-secondary fs-16 me-2";
    }
  };

  const getDecisionText = (decision) => {
    switch (decision) {
      case "SUCCESS":
        return "RÉUSSI";
      case "FAILED":
        return "ÉCHOUÉ";
      default:
        return "NON SPÉCIFIÉ";
    }
  };

  const errorOptions = {
    loop: false,
    autoplay: true,
    animationData: errorAnimationData,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };

  return (
    <React.Fragment>
      <section className="section">
        <Container className="mt-5">
          <div className="text-start">
            <p>
              Voici les résultats de votre test <strong>{evaluate}</strong>.{" "}
            </p>
          </div>
          <Card>
            <CardBody>
              <h5 className="text-center mb-4">
                <span className="badge bg-warning-subtle text-warning fs-20 me-2">
                  Examen
                </span>
                {endTime && !isNaN(new Date(endTime))
                  ? new Intl.DateTimeFormat("fr-FR", {
                      dateStyle: "full",
                    }).format(new Date(endTime))
                  : "Date non disponible"}
                | {totalQuestions} Questions | {duration} Min | 50% nécessaire
                pour réussir ce test
              </h5>
              {error && (
                <div className="text-center">
                  <Lottie options={errorOptions} height={200} width={200} />
                  <p className="text-danger mt-3">
                    Une erreur est survenue! Veuillez contacter l'entreprise.
                  </p>
                </div>
              )}
              <div className="row align-items-center text-center">
                {loading && (
                  <div className="col-12">
                    <h4 className="text-info mt-3 mb-3">
                      Veuillez patienter vos résultats seront disponibles sous
                      peu !
                    </h4>
                  </div>
                )}
                <div className="col-md-3 d-flex flex-column align-items-start">
                  {loading ? (
                    <Skeleton count={4} />
                  ) : (
                    <>
                      <p>
                        <strong>Début :</strong>{" "}
                        {new Date(startTime).toLocaleTimeString("fr-FR", {
                          hour: "2-digit",
                          minute: "2-digit",
                        })}{" "}
                        -<strong> Fin :</strong>{" "}
                        {new Date(endTime).toLocaleTimeString("fr-FR", {
                          hour: "2-digit",
                          minute: "2-digit",
                        })}
                      </p>
                      <p>
                        <strong>Durée :</strong> {minutes} Min
                        {minutes !== 1 ? "s" : ""} et {seconds} Seconde
                        {seconds !== 1 ? "s" : ""}
                      </p>
                    </>
                  )}
                </div>
                <div className="col-md-2 d-flex flex-column align-items-end">
                  {loading ? (
                    <Skeleton count={1} />
                  ) : (
                    <>
                      <h1 className="mb-3">
                        {Math.ceil(testResult?.score || 0)}%
                      </h1>
                    </>
                  )}
                </div>
                <div className="col-md-4 d-flex flex-column align-items-center">
                  <div
                    className="chart-container"
                    style={{ width: "250px", height: "250px" }}
                  >
                    {loading ? (
                      <Skeleton circle={true} height={250} width={250} />
                    ) : (
                      <Pie data={pieData} options={pieOptions} />
                    )}
                  </div>
                </div>
                <div className="col-md-3 d-flex flex-column align-items-end">
                  {loading ? (
                    <Skeleton count={1} />
                  ) : (
                    <>
                      <p>
                        <strong>Décision : </strong>
                        <span
                          className={`${getBadgeClass(testResult?.decision)}`}
                        >
                          {getDecisionText(testResult?.decision)}
                        </span>
                      </p>
                    </>
                  )}
                </div>
              </div>
            </CardBody>
          </Card>
          <Nav
            tabs
            className="job-list-menu nav-pills nav-justified flex-column flex-sm-row mb-4"
            id="pills-tab"
            role="tablist"
          >
            <NavItem>
              <NavLink
                className={classnames({ active: activeTab === "1" })}
                onClick={() => toggleTab("1")}
                type="button"
                role="tab"
              >
                Toutes les questions
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink
                className={classnames({
                  active: activeTab === "2",
                  "bg-red text-white": activeTab === "2", // Bleu pour Correctes
                })}
                onClick={() => toggleTab("2")}
                type="button"
                role="tab"
              >
                Correctes
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink
                className={classnames({
                  active: activeTab === "3",
                  "bg-cyan text-white": activeTab === "3", // Rouge pour Incorrectes
                })}
                onClick={() => toggleTab("3")}
                type="button"
                role="tab"
              >
                Incorrectes
              </NavLink>
            </NavItem>
          </Nav>

          <TabContent activeTab={activeTab} className="mt-4">
            <TabPane tabId="1">
              {loading ? (
                <Skeleton count={5} height={40} />
              ) : (
                <Table striped>
                  <thead>
                    <tr>
                      <th></th>
                      <th>Question</th>
                      <th>Votre réponse</th>
                      <th>Réponse correcte</th>
                    </tr>
                  </thead>
                  <tbody>
                    {testResult?.results.map((r, index) => (
                      <tr key={index}>
                        <td>{getStatusIcon(r.answerStatus === "CORRECT")}</td>
                        <td>{r.question}</td>
                        <td>{r.answer}</td>
                        <td>{r.correctAnswer}</td>
                      </tr>
                    ))}
                  </tbody>
                </Table>
              )}
            </TabPane>

            <TabPane tabId="2">
              {loading ? (
                <Skeleton count={5} height={40} />
              ) : (
                <Table striped>
                  <thead>
                    <tr>
                      <th></th>
                      <th>Question</th>
                      <th>Votre réponse</th>
                    </tr>
                  </thead>
                  <tbody>
                    {testResult?.results
                      .filter((r) => r.answerStatus === "CORRECT")
                      .map((r, index) => (
                        <tr key={index}>
                          <td>{getStatusIcon(true)}</td>
                          <td>{r.question}</td>
                          <td>{r.answer}</td>
                        </tr>
                      ))}
                  </tbody>
                </Table>
              )}
            </TabPane>

            <TabPane tabId="3">
              {loading ? (
                <Skeleton count={5} height={40} />
              ) : (
                <Table striped>
                  <thead>
                    <tr>
                      <th></th>
                      <th>Question</th>
                      <th>Votre réponse</th>
                      <th>Réponse correcte</th>
                    </tr>
                  </thead>
                  <tbody>
                    {testResult?.results
                      .filter((r) => r.answerStatus !== "CORRECT")
                      .map((r, index) => (
                        <tr key={index}>
                          <td>{getStatusIcon(false)}</td>
                          <td>{r.question}</td>
                          <td>{r.answer}</td>
                          <td>{r.correctAnswer}</td>
                        </tr>
                      ))}
                  </tbody>
                </Table>
              )}
            </TabPane>
          </TabContent>
        </Container>
      </section>
    </React.Fragment>
  );
};

export default ServicePage;
