import React, { useState, useEffect } from "react";
import {
  Card,
  CardBody,
  Form,
  FormGroup,
  Label,
  Row,
  Col,
  Button as ReactstrapButton,
} from "reactstrap";
// import { DownOutlined, RightOutlined } from "@ant-design/icons";
import { useDispatch, useSelector } from "react-redux";
import {
  fetchLanguageTypes,
  fetchLevelTypes,
  addCandidateLanguage,
  fetchCandidateLanguageById,
  updateCandidateLanguage,
  resetSelectedDetails,
} from "../../../../redux/profile/action";
import { toast, ToastContainer } from "react-toastify";

const LanguageForm = ({ selectedLanguage, onCancel }) => {
  const [language, setLanguage] = useState({
    languageId: "",
    level: "",
  });

  const dispatch = useDispatch();
  const { languageTypes, selectedDetails } = useSelector(
    (state) => state.profileReducer
  );

  // Fonction pour transformer les données de langue sélectionnée
  const transformLanguageData = (selectedDetails) => {
    return {
      id: selectedDetails?.id,
      languageId: selectedDetails.language?.id || "",
      level: selectedDetails.level || "",
    };
  };

  // Charger les types de langues et les détails si une langue est sélectionnée
  useEffect(() => {
    dispatch(fetchLanguageTypes());
    dispatch(fetchLevelTypes());
    if (selectedLanguage) {
      dispatch(fetchCandidateLanguageById(selectedLanguage?.id));
    }
  }, [dispatch, selectedLanguage]);

  useEffect(() => {
    if (selectedDetails) {
      const formattedLanguage = transformLanguageData(selectedDetails);
      setLanguage(formattedLanguage);
    } else if (selectedLanguage) {
      const formattedLanguage = transformLanguageData(selectedLanguage);
      setLanguage(formattedLanguage);
    } else {
      resetForm();
    }
  }, [selectedDetails, selectedLanguage]);

  const handleChange = (e) => {
    setLanguage({ ...language, [e.target.name]: e.target.value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (selectedLanguage?.id) {
      dispatch(updateCandidateLanguage(language))
        .then(() => {
          resetForm();
          onCancel();
        })
        .catch(() => {
          toast.error("Une erreur est survenue lors de la modification !");
        });
    } else {
      dispatch(addCandidateLanguage(language))
        .then(() => {
          resetForm();
          onCancel();
        })
        .catch(() => {
          toast.error("Une erreur est survenue lors de l'ajout !");
        });
    }
  };

  const resetForm = () => {
    setLanguage({
      languageId: "",
      level: "",
    });
  };

  return (
    <Card>
      <CardBody>
        <Form onSubmit={handleSubmit}>
          <Row>
            <Col md={6}>
              {/* Langue */}
              <FormGroup>
                <Label for="languageId">Langue*</Label>
                <select
                  className="form-select"
                  name="languageId"
                  id="languageId"
                  value={language.languageId}
                  onChange={handleChange}
                  required
                >
                  <option value="">Sélectionnez une langue</option>
                  {languageTypes &&
                    languageTypes.map((type) => (
                      <option key={type.id} value={type.id}>
                        {type.name}
                      </option>
                    ))}
                </select>
              </FormGroup>
            </Col>
            <Col md={6}>
              {/* Niveau */}
              <FormGroup>
                <Label for="level">Niveau*</Label>
                <select
                  className="form-select"
                  name="level"
                  id="level"
                  value={language.level}
                  onChange={handleChange}
                  required
                >
                  <option value="">Sélectionnez votre niveau</option>
                  <option value="BEGINNER">Débutant</option>
                  <option value="INTERMEDIARY">Intermediaire</option>
                  <option value="EXPERT">Avancé</option>
                </select>
              </FormGroup>
            </Col>
          </Row>

          <div className="text-end">
            <ReactstrapButton type="submit" color="danger" className="me-2">
              {selectedLanguage?.id ? "Modifier" : "Ajouter"} la langue
            </ReactstrapButton>
            <ReactstrapButton
              color="primary"
              onClick={() => {
                resetForm();
                dispatch(resetSelectedDetails());
                onCancel();
              }}
              className="ms-2"
            >
              Annuler
            </ReactstrapButton>
          </div>
        </Form>
        <ToastContainer />
      </CardBody>
    </Card>
  );
};

export default LanguageForm;
