import React, { useEffect, useState } from "react";
import {
  Col,
  Row,
  Nav,
  NavLink,
  TabContent,
  TabPane,
  Card,
  NavItem,
  CardBody,
  Modal,
  ModalBody,
  Input,
  Button,
  ModalFooter,
} from "reactstrap";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { Popconfirm } from "antd";
import {
  fetchBeneficiaryProjets,
  fetchBeneficiaryAcademies,
  addProject,
  deleteProject,
  updateProject,
} from "../../../redux/training/action";
import classnames from "classnames";
import Lottie from "react-lottie";
import { toast, ToastContainer } from "react-toastify";
import * as loadingAnimationData from "../../../../src/assets/loading.json";
import * as errorAnimationData from "../../../../src/assets/error.json"; // Animation pour l'erreur
import * as emptyAnimationData from "../../../../src/assets/emptyData.json"; // Animation pour données vides
import { Table } from "antd";

const BeneficiaryContent = () => {
  const [activeTab, setActiveTab] = useState("1");
  const [modal, setModal] = useState(false);
  const [isEditMode, setIsEditMode] = useState(false);
  const [currentProjectId, setCurrentProjectId] = useState(null);
  const [formData, setFormData] = useState({
    libelle: "",
    readMe: "",
    academyId: "",
  });
  const dispatch = useDispatch();

  const { allAcademies } = useSelector((state) => state.trainingReducer);
  const { loading, beneficiaryProjects, error } = useSelector(
    (state) => state.trainingReducer
  );

  useEffect(() => {
    dispatch(fetchBeneficiaryAcademies());
    dispatch(fetchBeneficiaryProjets());
    window.scrollTo(0, 0);
  }, [dispatch]);

  const openProjectDialog = () => {
    setModal(true);
    setFormData({ libelle: "", readMe: "", academyId: "" }); // Réinitialiser le formulaire
  };

  const handleCloseModal = () => {
    setModal(false);
  };

  const handleEditProject = (project) => {
    // console.log("reada", project);
    setFormData({
      libelle: project.libelle,
      readMe: project.readMe,
      academyId: project.academyId,
    });
    setCurrentProjectId(project.key); // Stocker l'ID du projet pour la modification
    setIsEditMode(true); // Indiquer que c'est une modification
    setModal(true);
  };

  const handleDeleteProject = (projectId) => {
    dispatch(deleteProject({ projectId }))
      .then(() => {
        handleCloseModal();
        toast.success("Projet supprimé avec succes!");
        // window.location.reload();
      })
      .catch(() => {
        toast.error("Une erreur est survenue! Veuillez réessayer");
      });
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prev) => ({ ...prev, [name]: value }));
  };

  const handleSubmit = () => {
    if (!formData.libelle || !formData.readMe || !formData.academyId) {
      toast.error("Tous les champs sont obligatoires!");
      return;
    }

    const { libelle, readMe } = formData;
    // console.log("read", formData);

    if (isEditMode && currentProjectId) {
      // Si nous sommes en mode modification, créer l'objet avec l'ID du projet
      const projectData = {
        id: currentProjectId, // ID du projet existant
        libelle,
        readMe,
      };

      // Appeler l'API de mise à jour avec cet objet
      dispatch(updateProject(projectData))
        .then(() => {
          handleCloseModal();
          toast.success("Projet modifié avec succès!");
        })
        .catch(() => {
          toast.error("Une erreur est survenue lors de la modification!");
        });
    } else {
      // Appeler l'API d'ajout
      dispatch(addProject(formData))
        .then(() => {
          handleCloseModal();
          toast.success("Projet ajouté avec succès!");
        })
        .catch(() => {
          toast.error("Une erreur est survenue lors de l'ajout!");
        });
    }
  };

  const getBadgeClass = (status) => {
    switch (status) {
      case "VALID":
        return "badge bg-success-subtle text-success fs-10 me-2"; // Accepté
      case "IN_PROGRESS":
        return "badge bg-info-subtle text-info fs-10 me-2"; // En cours
      case "REJECT":
        return "badge bg-danger-subtle text-danger fs-10 me-2"; // Rejeté
      case null:
      default:
        return "badge bg-warning-subtle text-warning fs-10 me-2"; // En attente
    }
  };

  const columns = [
    {
      title: "Libellé",
      dataIndex: "libelle",
      key: "libelle",
      render: (text) => (
        <strong style={{ textTransform: "uppercase" }}>{text}</strong>
      ), // Affichage en majuscules
    },
    {
      title: "Décision",
      dataIndex: "decisionProjectStatus",
      key: "decisionProjectStatus",
      render: (status) => (
        <span className={getBadgeClass(status)}>
          {status === "VALID"
            ? "ACCEPTÉ"
            : status === "IN_PROGRESS"
            ? "EN COURS"
            : status === "REJECT"
            ? "REJETÉ"
            : "EN ATTENTE"}
        </span>
      ), // Affichage du badge de statut
    },
    {
      title: "Académie",
      dataIndex: "academy",
      key: "academy",
      render: (academy) => academy.title || "N/A",
    },
    {
      title: "Actions",
      key: "actions",
      render: (record) => (
        <>
          <Link
            onClick={() => handleEditProject(record)} // Fonction pour modifier le projet
            to="#"
            className="text-success me-2" // Supprimez les classes pour le fond et les bordures
          >
            <i className="uil uil-edit-alt fs-20"></i>{" "}
            {/* Conservez la taille de l'icône */}
          </Link>
          <Popconfirm
            title="Êtes-vous sûr de vouloir supprimer ce projet ?"
            onConfirm={() => handleDeleteProject(record.key)} // Action si confirmé
            okText="Oui"
            cancelText="Non"
          >
            <Link to="#" className="text-danger">
              <i className="uil uil-trash-alt fs-20"></i>
            </Link>
          </Popconfirm>
        </>
      ),
    },
  ];

  const data = beneficiaryProjects?.content?.map((project) => ({
    key: project.id,
    libelle: project.libelle,
    decisionProjectStatus: project.decisionProjectStatus,
    academy: project.beneficiaryAcademy.academyClass.academy,
    readMe: project.readMe,
    academyId: project.beneficiaryAcademy.academyClass.academy.id,
  }));

  // Paramètres pour l'animation Lottie de chargement
  const loadingOptions = {
    loop: true,
    autoplay: true,
    animationData: loadingAnimationData,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };

  // Paramètres pour l'animation Lottie d'erreur
  const errorOptions = {
    loop: true,
    autoplay: true,
    animationData: errorAnimationData,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };

  // Paramètres pour l'animation Lottie de données vides
  const emptyOptions = {
    loop: true,
    autoplay: true,
    animationData: emptyAnimationData,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };

  return (
    <React.Fragment>
      <Col lg={12}>
        <Card className="profile-content-page mt-4 mb-3 mt-lg-0">
          <Nav
            className="profile-content-nav nav-pills border-bottom mb-4"
            id="pills-tab"
            role="tablist"
          >
            <NavItem role="mes projets">
              <NavLink
                to="#"
                className={classnames({ active: activeTab === "1" })}
                onClick={() => setActiveTab("1")}
                type="button"
              >
                Mes projets({beneficiaryProjects.totalElements})
              </NavLink>
            </NavItem>
          </Nav>

          <CardBody className="p-4">
            <TabContent activeTab={activeTab}>
              <TabPane tabId="1">
                <Row>
                  <Col>
                    <button
                      type="submit"
                      className="btn btn-outline-primary btn-hover float-start mb-3"
                      onClick={openProjectDialog}
                    >
                      <i className="uil uil-plus fs-16"></i>&nbsp; Soumettre un
                      projet
                    </button>
                  </Col>
                  <Modal
                    isOpen={modal}
                    toggle={handleCloseModal}
                    style={{ maxWidth: "600px" }}
                    centered
                  >
                    <ModalBody className="modal-body p-5">
                      <div className="position-absolute end-0 top-0 p-3">
                        <button
                          type="button"
                          onClick={handleCloseModal}
                          className="btn-close"
                          data-bs-dismiss="modal"
                          aria-label="Close"
                        ></button>
                      </div>
                      <h5 className="modal-title mb-4">
                        Ajouter / Modifier un projet
                      </h5>
                      <Input
                        name="libelle"
                        placeholder="Libellé du projet"
                        value={formData.libelle}
                        onChange={handleInputChange}
                        className="mb-3"
                        required
                      />
                      <Input
                        name="readMe"
                        placeholder="URL du document ReadMe"
                        value={formData.readMe}
                        onChange={handleInputChange}
                        className="mb-3"
                        required
                      />
                      <select
                        className="form-select"
                        name="academyId"
                        value={formData.academyId}
                        onChange={handleInputChange}
                        required
                      >
                        <option value="">Sélectionnez une académie</option>
                        {allAcademies?.content?.map((academy) => (
                          <option key={academy.id} value={academy.id}>
                            {academy.title}
                          </option>
                        ))}
                      </select>
                    </ModalBody>
                    <ModalFooter>
                      <Button color="primary" onClick={handleSubmit}>
                        Soumettre
                      </Button>
                      <Button color="danger" onClick={handleCloseModal}>
                        Annuler
                      </Button>
                    </ModalFooter>
                  </Modal>
                </Row>
                {loading ? (
                  <Lottie options={loadingOptions} height={100} width={100} />
                ) : error ? (
                  <div className="text-center mt-5">
                    <Lottie options={errorOptions} height={100} width={100} />
                    <p className="text-danger mt-3">
                      Une erreur s'est produite : {error}
                    </p>
                  </div>
                ) : beneficiaryProjects?.length === 0 ? (
                  <div className="text-center mt-5">
                    <Lottie options={emptyOptions} height={100} width={100} />
                    <p className="text-muted mt-3">
                      Aucun projet trouvé. Veuillez réessayer plus tard.
                    </p>
                  </div>
                ) : (
                  <>
                    <Table
                      size="small"
                      columns={columns}
                      expandable={{
                        expandedRowRender: (record) => (
                          <p style={{ margin: 0 }}>
                            <a
                              href={record.readMe}
                              target="_blank"
                              rel="noopener noreferrer"
                            >
                              {record.readMe}
                            </a>
                          </p>
                        ),
                        rowExpandable: (record) =>
                          record.libelle !== "Not Expandable",
                      }}
                      dataSource={data}
                    />
                    <ToastContainer />
                  </>
                )}
              </TabPane>
            </TabContent>
          </CardBody>
        </Card>
      </Col>
    </React.Fragment>
  );
};

export default BeneficiaryContent;
