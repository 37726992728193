import React, { useEffect, useState, useCallback, useMemo } from "react";
import {
  Container,
  Button,
  Card,
  CardBody,
  Modal,
  ModalBody,
  ModalFooter,
} from "reactstrap";
import { useTimer } from "react-timer-hook";
import { useLocation, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  fetchCandidateQuestionTest,
  beginCandidateTest,
} from "../../../redux/test/action";
import { notification } from "antd";
import Lottie from "react-lottie";

import * as emptyAnimationData from "../../../../src/assets/emptyData.json"; // Animation pour l'erreur
import * as loadingAnimationData from "../../../../src/assets/loading.json"; // Animation pour l'erreur

const useQuery = () => {
  return new URLSearchParams(useLocation().search);
};

const PricingPage = () => {
  const [step, setStep] = useState(1);
  const [expiryTime, setExpiryTime] = useState(null);
  const [answers, setAnswers] = useState({});
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [startTime, setStartTime] = useState(null); // Initialize as null
  const [isTestAuthorized, setIsTestAuthorized] = useState(false); // New state to track test authorization
  const navigate = useNavigate();
  const query = useQuery();
  const access_token = query.get("access_token") || "";
  const dispatch = useDispatch();

  const { testQuestions, loading, error } = useSelector(
    (state) => state.testReducer
  );

  const [api, contextHolder] = notification.useNotification();
  const openNotificationWithIcon = (type) => {
    api[type]({
      message: "Accun choix fait",
      description: "Veuillez sélectionner une réponse avant de continuer.",
      placement: "top",
    });
  };

  useEffect(() => {
    dispatch(fetchCandidateQuestionTest(access_token));
    window.scrollTo(0, 0);
  }, [dispatch, access_token]);

  const questions = useMemo(() => {
    return (
      testQuestions?.questions?.map((q, index) => ({
        id: index + 1,
        question: q.question,
        options: Object.values(q.propositionResponse),
        correctAnswer: Object.values(q.propositionResponse)[0],
      })) || []
    );
  }, [testQuestions]);

  const totalSteps = questions.length + 2;

  const handleFinish = useCallback(async () => {
    if (!startTime || !isTestAuthorized) {
      console.error("Test is not authorized or startTime is not set!");
      return;
    }

    const endTime = new Date();
    const elapsedSeconds = Math.floor((endTime - startTime) / 1000);
    const minutes = Math.floor(elapsedSeconds / 60);
    const seconds = elapsedSeconds % 60;

    const formattedResponses = questions.map((q) => ({
      question: q.question,
      propositionResponse: q.options.reduce((acc, option, index) => {
        acc[`response${index + 1}`] = option;
        return acc;
      }, {}),
      response: answers[q.id] || "je ne sais pas",
    }));

    const responseData = {
      responses: formattedResponses,
    };

    navigate("/result-technical-test", {
      state: {
        access_token: access_token,
        responseData: responseData,
        startTime: startTime.toISOString(),
        endTime: endTime.toISOString(),
        minutes,
        seconds,
        totalQuestions: questions.length,
        duration: testQuestions?.duration,
        evaluate: testQuestions?.evaluated?.lastName,
        company: testQuestions?.author?.name,
      },
    });
    setIsModalOpen(false);
  }, [
    startTime,
    isTestAuthorized,
    questions,
    answers,
    navigate,
    access_token,
    testQuestions,
  ]);

  useEffect(() => {
    const handleVisibilityChange = () => {
      if (
        document.visibilityState === "hidden" &&
        startTime &&
        isTestAuthorized
      ) {
        handleFinish();
      }
    };

    const handleWindowBlur = () => {
      if (startTime && isTestAuthorized) {
        handleFinish();
      }
    };

    const handleBeforeUnload = () => {
      if (startTime && isTestAuthorized) {
        handleFinish();
      }
    };

    document.addEventListener("visibilitychange", handleVisibilityChange);
    window.addEventListener("blur", handleWindowBlur);
    window.addEventListener("beforeunload", handleBeforeUnload);

    return () => {
      document.removeEventListener("visibilitychange", handleVisibilityChange);
      window.removeEventListener("blur", handleWindowBlur);
      window.removeEventListener("beforeunload", handleBeforeUnload);
    };
  }, [startTime, isTestAuthorized, handleFinish]);

  // Timer : utiliser `useTimer` pour gérer le décompte
  const { seconds, minutes, restart } = useTimer({
    expiryTimestamp: expiryTime,
    autoStart: false,
    onExpire: handleFinish,
  });

  // Initialiser le minuteur au step 2
  useEffect(() => {
    if (step === 2 && testQuestions?.duration) {
      const time = new Date();
      time.setSeconds(time.getSeconds() + testQuestions.duration * 60);
      setExpiryTime(time);
      setStartTime(new Date());
      setIsTestAuthorized(true); // Set test as authorized
      restart(time);
    }
  }, [step, testQuestions, restart]);

  const handleNext = () => {
    if (step === 2) {
      dispatch(beginCandidateTest(access_token));
    }
    if (step > 2 && !answers[questions[step - 3].id]) {
      openNotificationWithIcon("warning");
      return;
    }

    if (step < totalSteps) {
      setStep(step + 1);
    } else if (step === totalSteps) {
      setIsModalOpen(true);
    }
  };

  const handleBack = () => {
    if (step > 1) setStep(step - 1);
  };

  const handleAnswer = (questionId, answer) => {
    setAnswers((prev) => ({
      ...prev,
      [questionId]: answer,
    }));
  };

  // Options for Lottie animations
  const loadingOptions = {
    loop: true,
    autoplay: true,
    animationData: loadingAnimationData,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };

  const errorOptions = {
    loop: false,
    autoplay: true,
    animationData: emptyAnimationData,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };

  // ... existing code for rendering

  return (
    <React.Fragment>
      <section className="section bg-light">
        {contextHolder}
        <Container className="mt-1 mb-5">
          {/* Affichage de l'animation de chargement */}
          {loading && (
            <div className="text-center">
              <Lottie options={loadingOptions} height={200} width={200} />
            </div>
          )}

          {/* Affichage de l'animation d'erreur */}
          {error && (
            <div className="text-center">
              <Lottie options={errorOptions} height={200} width={200} />
              <p className="text-danger mt-3">
                Access non autorisé! Ce test a été déja effectué.
              </p>
            </div>
          )}
          {/* Affichage du contenu principal si pas de chargement ou d'erreur */}
          {!loading && !error && (
            <>
              {step >= 3 && (
                <div className="text-end">
                  Temps restant : {minutes}:
                  {seconds < 10 ? `0${seconds}` : seconds}
                </div>
              )}
              {step === 1 && (
                <div className="text-start">
                  <p>
                    Bienvenu au test{" "}
                    <strong>{testQuestions?.evaluated?.lastName}</strong>,
                    Veuillez prendre connaissance des instructions avant de
                    débuter.
                  </p>
                </div>
              )}
              {/* Étape 1 : Instructions générales */}
              {step === 1 && (
                <Card>
                  <CardBody>
                    <h3 className="text-center mb-4">Instructions</h3>
                    <p>
                      Nombre de questions : <strong>{questions.length}</strong>
                    </p>
                    <p>
                      Limite de temps :{" "}
                      <strong>{testQuestions?.duration || 20} minutes</strong>
                    </p>
                    <p>
                      Le test doit être terminé en une seule session. Vous ne
                      pouvez pas sauvegarder et terminer plus tard.
                    </p>
                    <p>
                      Nombre de questions affichées par page :{" "}
                      <strong>1</strong>
                    </p>
                    <p>
                      Vous pouvez revenir en arrière pour modifier vos réponses.
                    </p>
                    <p>
                      Toutes les questions doivent être répondues avant de
                      terminer.
                    </p>
                    <p>
                      Note de passage : <strong>50%</strong>
                    </p>
                    <p className="text-danger">
                      Attention : Si vous rafraichissez la page, changez
                      d'onglet ou de fenêtre au cour du test, celui ci se
                      terminera automatiquement.
                    </p>
                    <div className="d-flex justify-content-end">
                      <Button color="primary" onClick={handleNext}>
                        <i className="uil uil-arrow-right fs-16"></i>&nbsp;
                        Suivant
                      </Button>
                    </div>
                  </CardBody>
                </Card>
              )}

              {/* Étape 2 : Instructions détaillées */}
              {step === 2 && (
                <Card>
                  <CardBody>
                    <h3 className="text-center mb-4">Consignes importantes</h3>
                    <p>
                      Aucune partie de cette évaluation ne peut être reproduite,
                      distribuée ou transmise sous quelque forme ou par quelque
                      moyen que ce soit, y compris la photocopie,
                      l'enregistrement ou d'autres méthodes électroniques ou
                      mécaniques, sans l'autorisation écrite préalable de
                      l'administrateur du test. La violation des droits d'auteur
                      peut entraîner des poursuites judiciaires à votre encontre
                      et le blocage de votre IP sur la plate-forme d'accueil du
                      test.
                    </p>
                    <p>Copyright © 2024 RecruTech.pro. Tous droits réservés.</p>
                    <div className="d-flex justify-content-between">
                      <Button color="secondary" onClick={handleBack}>
                        <i className="uil uil-arrow-left fs-16"></i>&nbsp;
                        Retour
                      </Button>
                      <Button color="primary" onClick={handleNext}>
                        <i className="uil uil-arrow-right fs-16"></i>
                        &nbsp;Commencer le test
                      </Button>
                    </div>
                  </CardBody>
                </Card>
              )}

              {/* Étapes des questions */}
              {step > 2 && step <= totalSteps && (
                <Card>
                  <CardBody>
                    <h4 className="text-center mb-4">
                      Question {step - 2} sur {questions.length}
                    </h4>
                    <div className="row align-items-start">
                      <div className="col-md-12">
                        <h5 className="mb-4 text-primary">
                          {questions[step - 3].question}
                        </h5>
                      </div>

                      <div className="col-md-12">
                        {questions[step - 3].options.map((option, index) => (
                          <div key={index} className="form-check mb-3">
                            <input
                              type="radio"
                              className="form-check-input"
                              name={`question-${questions[step - 3].id}`}
                              id={`question-${
                                questions[step - 3].id
                              }-option-${index}`}
                              checked={
                                answers[questions[step - 3].id] === option
                              }
                              onChange={() =>
                                handleAnswer(questions[step - 3].id, option)
                              }
                            />
                            <label
                              className="form-check-label fs-5"
                              htmlFor={`question-${
                                questions[step - 3].id
                              }-option-${index}`}
                            >
                              {option}
                            </label>
                          </div>
                        ))}
                      </div>
                    </div>

                    <div className="d-flex justify-content-between mt-4">
                      {step > 3 && (
                        <Button color="danger" onClick={handleBack}>
                          <i className="uil uil-arrow-left fs-16"></i>&nbsp;
                          Retour
                        </Button>
                      )}
                      <Button
                        color="primary"
                        onClick={handleNext}
                        disabled={
                          step === totalSteps &&
                          !Object.keys(answers).length === questions.length
                        }
                      >
                        <i className="uil uil-arrow-right fs-16"></i>&nbsp;
                        {step === totalSteps ? "Terminer le test" : "Suivant"}
                      </Button>
                    </div>
                  </CardBody>
                </Card>
              )}
            </>
          )}
        </Container>

        {/* Modal de confirmation */}
        <Modal isOpen={isModalOpen} toggle={() => setIsModalOpen(!isModalOpen)}>
          <ModalBody>Voulez-vous vraiment terminer le test ?</ModalBody>
          <ModalFooter>
            <Button color="secondary" onClick={() => setIsModalOpen(false)}>
              Retourner
            </Button>
            <Button color="primary" onClick={handleFinish}>
              Terminer maintenant
            </Button>
          </ModalFooter>
        </Modal>
      </section>
    </React.Fragment>
  );
};

export default PricingPage;
