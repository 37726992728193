import TrainingService from "../../../service/training";
import * as TrainingActionTypes from "../types";
// import TokenService from "../../../service/TokenService";

export const fetchAllTrainingsPaged = (page) => {
  return async (dispatch) => {
    dispatch({ type: TrainingActionTypes.FETCH_TRAININGS_REQUEST });

    try {
      // Récupérer l'utilisateur connecté
      // const currentUser = TokenService.getUser();
      // const userRole = currentUser?.authorities[0]; // Récupérer le rôle de l'utilisateur

      let response;
      if (page.slug) {
        response = await TrainingService.fetchCandidteTrainingsAcademy(
          page.slug
        );
        // console.log("Fetching all training", page.slug);
      } else {
        // console.log("Fetching all training", page);
        response = await TrainingService.fetchCandidteTrainings(page);
      }
      dispatch({
        type: TrainingActionTypes.FETCH_TRAININGS_SUCCESS,
        payload: response.data,
      });

      // Vérification du rôle et appel API approprié
      // if (userRole === "ROLE_BENEFICIARY_ACADEMY") {
      //   response = await TrainingService.fetchBeneficiaryTrainings();
      //   dispatch({
      //     type: TrainingActionTypes.FETCH_TRAININGS_SUCCESS,
      //     payload: response,
      //   });
      // } else if (userRole === "ROLE_CANDIDATE") {
      //   response = await TrainingService.fetchCandidteTrainings(page);
      //   dispatch({
      //     type: TrainingActionTypes.FETCH_TRAININGS_SUCCESS,
      //     payload: response.data,
      //   });
      // } else {
      //   response = await TrainingService.fetchCandidteTrainings(page);
      //   dispatch({
      //     type: TrainingActionTypes.FETCH_TRAININGS_SUCCESS,
      //     payload: response.data,
      //   });
      // }
    } catch (error) {
      dispatch({
        type: TrainingActionTypes.FETCH_TRAININGS_FAILURE,
        payload: error.message,
      });
    }
  };
};

export const fetchBeneficiaryAcademies = () => {
  return async (dispatch) => {
    dispatch({ type: TrainingActionTypes.FETCH_ACADEMIES_REQUEST });
    try {
      const response = await TrainingService.fetchBeneficiaryAcademies();
      if (response && response.data && response.data.statusCode === 200) {
        dispatch({
          type: TrainingActionTypes.FETCH_ACADEMIES_SUCCESS,
          payload: response.data,
        });
      }
    } catch (error) {
      dispatch({
        type: TrainingActionTypes.FETCH_ACADEMIES_FAILURE,
        payload: error.message,
      });
    }
  };
};

export const fetchBeneficiaryProjets = () => {
  return async (dispatch) => {
    dispatch({ type: TrainingActionTypes.FETCH_PROJECTS_REQUEST });
    try {
      const response = await TrainingService.fetchBeneficiaryProjects();
      if (response && response.data && response.statusCode === 200) {
        // console.log("ccc", response.data);
        dispatch({
          type: TrainingActionTypes.FETCH_PROJECTS_SUCCESS,
          payload: response.data,
        });
      }
    } catch (error) {
      dispatch({
        type: TrainingActionTypes.FETCH_PROJECTS_FAILURE,
        payload: error.message,
      });
    }
  };
};

export const addProject = (projectData) => {
  return async (dispatch) => {
    dispatch({ type: TrainingActionTypes.ADD_PROJECT_REQUEST });
    try {
      const response = await TrainingService.addProject(projectData);
      dispatch({
        type: TrainingActionTypes.ADD_PROJECT_SUCCESS,
        payload: response.data,
      });
      // Optionnel : Récupérer les projets après l'ajout
      dispatch(fetchBeneficiaryProjets());
    } catch (error) {
      dispatch({
        type: TrainingActionTypes.ADD_PROJECT_FAILURE,
        payload: error.message,
      });
    }
  };
};

export const updateProject = (projectData) => {
  return async (dispatch) => {
    dispatch({ type: TrainingActionTypes.UPDATE_PROJECT_REQUEST });
    try {
      const response = await TrainingService.updateProject(projectData);
      dispatch({
        type: TrainingActionTypes.UPDATE_PROJECT_SUCCESS,
        payload: response.data,
      });
      // Optionnel : Récupérer les projets après la mise à jour
      dispatch(fetchBeneficiaryProjets());
    } catch (error) {
      dispatch({
        type: TrainingActionTypes.UPDATE_PROJECT_FAILURE,
        payload: error.message,
      });
    }
  };
};

export const deleteProject = (projectId) => {
  return async (dispatch) => {
    dispatch({ type: TrainingActionTypes.DELETE_PROJECT_REQUEST });
    try {
      await TrainingService.deleteProject(projectId);
      dispatch({
        type: TrainingActionTypes.DELETE_PROJECT_SUCCESS,
        payload: projectId,
      });
      // Optionnel : Récupérer les projets après la suppression
      dispatch(fetchBeneficiaryProjets());
    } catch (error) {
      dispatch({
        type: TrainingActionTypes.DELETE_PROJECT_FAILURE,
        payload: error.message,
      });
    }
  };
};

export const fetchTrainingDetails = (slug, trainingAcademy) => {
  return async (dispatch) => {
    dispatch({ type: TrainingActionTypes.SELECT_TRAINING_REQUEST });
    try {
      let response;
      if (trainingAcademy) {
        response = await TrainingService.fetchTrainingAcademyDetails(slug);
        // console.log("Fetching all training", response.data);
      } else {
        // console.log("Fetching all training", "nooo");
        response = await TrainingService.fetchTrainingDetails(slug);
      }
      if (response && response.data && response.status === 200) {
        dispatch({
          type: TrainingActionTypes.SELECT_TRAINING_SUCCESS,
          payload: response,
        });
      }
    } catch (error) {
      dispatch({
        type: TrainingActionTypes.SELECT_TRAINING_FAILURE,
        payload: error.message,
      });
    }
  };
};

export const fetchRecentTrainings = () => {
  return async (dispatch) => {
    dispatch({ type: TrainingActionTypes.FETCH_RECENT_TRAININGS_REQUEST });
    try {
      const response = await TrainingService.fetchRecentTrainings();
      if (response && response.data && response.data.statusCode === 200) {
        dispatch({
          type: TrainingActionTypes.FETCH_RECENT_TRAININGS_SUCCESS,
          payload: response.data,
        });
      }
    } catch (error) {
      dispatch({
        type: TrainingActionTypes.FETCH_RECENT_TRAININGS_FAILURE,
        payload: error.message,
      });
    }
  };
};

export const fetchAcademyDetails = (slug) => {
  return async (dispatch) => {
    dispatch({ type: TrainingActionTypes.SELECT_ACADEMY_REQUEST });
    try {
      const response = await TrainingService.fetchAcademyDetails(slug);
      if (response && response.data && response.statusCode === 200) {
        // console.log("ddd", response.data);
        dispatch({
          type: TrainingActionTypes.SELECT_ACADEMY_SUCCESS,
          payload: response,
        });
      }
    } catch (error) {
      dispatch({
        type: TrainingActionTypes.SELECT_ACADEMY_FAILURE,
        payload: error.message,
      });
    }
  };
};
