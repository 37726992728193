import React, { useState, useEffect } from "react";
import { Container, Row, Col } from "reactstrap";
import Section from "../../../pages/Blog/BlogGrid/Section";
import BlogText from "../../Blog/BlogGrid/BlogText";
import BlogCategory from "../../Blog/BlogGrid/BlogCategory";
import { useDispatch, useSelector } from "react-redux";
import { fetchTrainingDetails } from "../../../redux/training/action";
import { useParams } from "react-router-dom";
import Lottie from "react-lottie";
import * as animationData from "../../../assets/loading.json";
import "./style.css"; // Importer le fichier CSS pour les styles

const BlogGrid = () => {
  document.title = "Cours | RecruTech";

  const { slug } = useParams();
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(true);

  const { selectedDetails } = useSelector((state) => state.trainingReducer);

  const [activeModule, setActiveModule] = useState(null);
  const [selectedCourse, setSelectedCourse] = useState(null);

  useEffect(() => {
    // Début de la récupération des détails de formation
    dispatch(fetchTrainingDetails(slug));
    window.scrollTo(0, 0);
  }, [dispatch, slug]);

  useEffect(() => {
    // Vérifiez si les détails de formation sont chargés
    if (selectedDetails) {
      // Si selectedDetails a des modules et cours valides
      if (
        selectedDetails.trainingModules &&
        selectedDetails.trainingModules.length > 0
      ) {
        const firstModule = selectedDetails.trainingModules[0];
        setActiveModule(firstModule.id);

        // Sélectionner le premier cours du premier module
        if (firstModule.courses && firstModule.courses.length > 0) {
          setSelectedCourse(firstModule.courses[0]);
        }
      }
      // Fin du chargement des données
      setLoading(false);
    }
  }, [selectedDetails]);

  // Paramètres pour l'animation Lottie
  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: animationData,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };

  return (
    <React.Fragment>
      <Section selectedDetails={selectedDetails} />
      <section className="section">
        {loading ? (
          <div className="text-center">
            <Lottie options={defaultOptions} height={300} width={300} />
          </div>
        ) : (
          <div className="text-center">
            <Lottie options={defaultOptions} height={300} width={300} />
          </div>
          // <Container>
          //   <Row className="blog-grid">
          //     {/* Affichage des détails du cours dans la colonne droite */}
          //     <Col lg={8} md={8}>
          //       <div className="blog-post-container">
          //         {selectedCourse ? (
          //           <BlogText course={selectedCourse} />
          //         ) : (
          //           <p className="text-muted">
          //             Sélectionnez un cours pour voir les détails.
          //           </p>
          //         )}
          //       </div>
          //     </Col>
          //     {/* Affichage des modules et cours via BlogCategory */}
          //     <Col lg={4} md={4}>
          //       <div className="sidebar-container">
          //         <BlogCategory
          //           modules={selectedDetails?.trainingModules}
          //           onSelectCourse={setSelectedCourse} // Mettre à jour le cours sélectionné
          //           activeModule={activeModule}
          //           setActiveModule={setActiveModule}
          //           selectedCourse={selectedCourse} // Passer le cours sélectionné
          //         />
          //       </div>
          //     </Col>
          //   </Row>
          // </Container>
        )}
      </section>
    </React.Fragment>
  );
};

export default BlogGrid;
