import React, { useEffect } from "react";
import { Container, Row, Col, Nav } from "reactstrap";
import { Link } from "react-router-dom";
import { fetchCandidateQuestionTest } from "../../../redux/test/action";
import { useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import "./Pricing.css"; // Assurez-vous d'importer le fichier CSS

const useQuery = () => {
  return new URLSearchParams(useLocation().search);
};
const Section = () => {
  const query = useQuery();
  const access_token = query.get("access_token") || "";
  const dispatch = useDispatch();
  const { testQuestions } = useSelector((state) => state.testReducer);

  useEffect(() => {
    dispatch(fetchCandidateQuestionTest(access_token));
  }, [dispatch, access_token]);
  return (
    <React.Fragment>
      <section className="custom-background-section-test">
        <Container>
          <Row className="justify-content-center">
            <Col md={6}>
              <div className="text-center text-white">
                <h3 className="mb-4">{testQuestions?.author?.name}</h3>
                <div className="page-next">
                  <Nav
                    className="d-inline-block"
                    aria-label="breadcrumb text-center"
                  >
                    <ol className="breadcrumb justify-content-center">
                      <li className="breadcrumb-item">
                        <Link to="/">Acceuil</Link>
                      </li>
                      <li className="breadcrumb-item">
                        <Link to="#">Entreprise</Link>
                      </li>
                      <li
                        className="breadcrumb-item active"
                        aria-current="page"
                      >
                        {" "}
                        Test Technique{" "}
                      </li>
                    </ol>
                  </Nav>
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </section>
    </React.Fragment>
  );
};

export default Section;
