import React, { useEffect, useRef, useState } from "react";
import { Container, Col, Row } from "reactstrap";
// import Masonry from "react-masonry-component";
import { useDispatch, useSelector } from "react-redux";
import { fetchJobsPaged } from "../../../redux/job/action";
import Pagination from "../../CandidateAndCompany/CompanyList/Pagination";
import JobCardItem from "./JobCardItem";
import { useLocation } from "react-router-dom";
import Lottie from "react-lottie";

// import * as animationData from "../../../assets/loading.json"; // Remplacez par le chemin de votre fichier Lottie
import * as errorAnimationData from "../../../../src/assets/error.json"; // Animation pour l'erreur
import * as emptyAnimationData from "../../../../src/assets/emptyData.json"; // Animation pour données vides
import JobCardItemSkeleton from "./JobCardItemSkeleton";

const useQuery = () => {
  return new URLSearchParams(useLocation().search);
};

const JobVacancy = () => {
  const firstCardRef = useRef(null);
  // Gérer l'état de chargement
  const [loading, setLoading] = useState(true);
  const dispatch = useDispatch();
  const { job, error } = useSelector((state) => state.jobReducer);
  const query = useQuery();
  const companyId = query.get("companyId") || ""; // Récupérer l'ID de l'entreprise ou une chaîne vide

  const setCurrentPage = (page) => {
    dispatch(fetchJobsPaged(page, companyId)); // Passer l'ID de l'entreprise
  };

  useEffect(() => {
    dispatch(fetchJobsPaged(job.currentPage, companyId)); // Passer l'ID de l'entreprise
    window.scrollTo(0, 0);
  }, [dispatch, job.currentPage, companyId]); // Ajouter companyId comme dépendance

  // Gérer l'état de chargement en fonction des données ou des erreurs
  useEffect(() => {
    if (job) {
      // Simuler un délai de 3000ms (3 secondes)
      setTimeout(() => {
        setLoading(false);
      }, 1000); // 3000 millisecondes = 3 secondes
    }
  }, [job]);

  // Paramètres pour l'animation Lottie d'erreur
  const errorOptions = {
    loop: true,
    autoplay: true,
    animationData: errorAnimationData,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };
  // Paramètres pour l'animation Lottie de données vides
  const emptyOptions = {
    loop: true,
    autoplay: true,
    animationData: emptyAnimationData,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };
  return (
    <React.Fragment>
      <Container>
        {loading ? (
          <div className="text-center mt-5">
            <Row className="align-items-center mb-1">
              <Col md={6}>
                <JobCardItemSkeleton />
              </Col>
              <Col md={6}>
                <JobCardItemSkeleton />
              </Col>
            </Row>
          </div>
        ) : error ? (
          <div className="text-center mt-0">
            <Lottie options={errorOptions} height={300} width={300} />
            <p className="text-danger mt-0">
              Une erreur s'est produite : {error}
            </p>
          </div>
        ) : job.content.length === 0 ? (
          <div className="text-center mt-0">
            <Lottie options={emptyOptions} height={300} width={300} />
            <p className="text-muted mt-0">
              Aucune offre trouvée. Veuillez réessayer plus tard.
            </p>
          </div>
        ) : (
          <>
            <Row className="mb-3 align-items-center">
              {job.content.map((item, index) => (
                <JobCardItem
                  key={item.id}
                  job={item}
                  index={index}
                  firstCardRef={index === 0 ? firstCardRef : null}
                />
              ))}
            </Row>
            <Pagination
              currentPage={job.currentPage}
              totalPages={job.totalPages}
              fetchData={fetchJobsPaged}
              setCurrentPage={setCurrentPage}
              firstCardRef={firstCardRef}
              companyId={companyId} // Passer companyId à Pagination
            />
          </>
        )}
      </Container>
    </React.Fragment>
  );
};

export default JobVacancy;
